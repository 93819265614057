import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import {TelosEvmTestnet} from '@thirdweb-dev/chains'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
const projectId = '8e50e1aa009995cac307b3ab2933527f'
const mainnet = {
  chainId: 41, // Hexadecimal version of 80001, prefixed with 0x
	chainName: 'Telos EVM Testnet',
  currency:'TLOS',
	rpcUrl: 'https://testnet.telos.net/evm',
	explorerUrl: 'https://testnet.teloscan.io/',
}
const metadata = {
  name: 'casino',
  description: 'My Website description',
  url: 'https://telos-casino.vercel.app/', // origin must match your domain & subdomain
  
}
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: false, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 41, // used for the Coinbase SDK
})
createWeb3Modal({
  ethersConfig,
  chains: [TelosEvmTestnet],
  projectId,
  themeVariables: {
    '--w3m-color-mix': '#FFDB93',
    '--w3m-color-mix-strength': 60,
    '--w3m-border-radius-master': '50px',
    '--w3m-font-size-master':'14px',
    '--w3m-accent':'#000000'
  },
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThirdwebProvider  activeChain={TelosEvmTestnet} >
    <ReactNotifications isMobile={true} breakpoint={768} />
    <App />
    
    </ThirdwebProvider>
  
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
