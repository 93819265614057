import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider,useSwitchNetwork } from '@web3modal/ethers5/react';

const AdminPage = () => {
  const [pendingAddresses, setPendingAddresses] = useState([]);
  const [revokedAddresses, setRevokedAddresses] = useState([]);
  const [notrevokedAddresses, setNotRevokedAddresses] = useState([]);
  const [approvedAddresses, setApprovedAddresses] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newAdminAddress, setNewAdminAddress] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const contractAddress = '0x5C407066c5a18d76571d1071575bB029c2921E7d';
  const web3Modal = useWeb3Modal();
  const [currentAccount, setCurrentAccount] = useState(null);
  const contractABI =[
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressBanned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressUnbanned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "AdminAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "AdminRemoved",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "addAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "banAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "unbanAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "addAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "removeAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "isBanned",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "isAdmin",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllAdminAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "addresses",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "bannedAddresses",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "admins",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

  
  const switchNetwork = useSwitchNetwork();
const Telos = {
	chainId: 41, // Hexadecimal version of 80001, prefixed with 0x
	chainName: 'Telos EVM Testnet',
	nativeCurrency: {
	  name: 'Telos',
	  symbol: 'TLOS',
	  decimals: 18,
	},
	rpcUrls: ['https://testnet.telos.net/evm'],
	blockExplorerUrls: ['https://testnet.teloscan.io/'],
  };
  
  
  

  const { open: openWeb3Modal } = useWeb3Modal();
  const wallet = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    if (wallet) {
      setCurrentAccount(wallet.address);
      checkAdmin(wallet.address);

    }
  }, [wallet]);

  const checkAdmin = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const contract = new ethers.Contract(contractAddress, contractABI, provider);

      const isAdmin = await contract.isAdmin(address);
      
      const check = await contract.isAdmin('0x61DEa9da343Aab506535bbff37389e7B191Cc6E9')
      const check2 = await contract.isAdmin('0xcC990a736571436d21fE7e9BC7196C1CeEFD9790')
      const check3 = await contract.isAdmin('0x33246F1da551F2efa86b5877e3bAde8f28Abca2b')
      console.log(check,check2,check3,'checking');
      setIsAdmin(isAdmin);
      if (isAdmin) {
        fetchAddresses();
        fetchAdminAddresses()
      }
    } catch (error) {
      console.error("Failed to check admin status:", error);
    }
  };
  async function connectWallet() {
  
    try {
      await web3Modal.open();
      switchToTelos()
     
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  }
  async function switchToTelos() {
    try {
      await switchNetwork(Telos.chainId);
      
    } catch (error) {
      console.error("Failed to switch network:", error);
    }
    
    
}

  const fetchAddresses = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
      const allAddresses = await contract.getAllAddresses();
  
      const pending = new Set();
      const approved = new Set();
  
      for (const address of allAddresses) {
        if (pending.has(address) || approved.has(address)) continue; // Skip if address is already processed
  
        const isBanned = await contract.isBanned(address);
        if (isBanned) {
          pending.add(address);
        } else {
          approved.add(address);
        }
      }
  
      setPendingAddresses(Array.from(pending));
      setApprovedAddresses(Array.from(approved));
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };
  const fetchAdminAddresses = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
      const allAddresses = await contract.getAllAdminAddresses();
  
      const notrevoked = new Set();
      const revoked = new Set();
  
      for (const address of allAddresses) {
        if (notrevoked.has(address) || revoked.has(address)) continue; // Skip if address is already processed
  
        const isRevoked = await contract.isAdmin(address);
        if (isRevoked) {
          notrevoked.add(address);
        } else {
          revoked.add(address);
        }
      }
  
      setRevokedAddresses(Array.from(revoked));
      setNotRevokedAddresses(Array.from(notrevoked));
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };

  const addAddress = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      await contract.addAddress(address);
      console.log("Address added:", address);

      setPendingAddresses([...pendingAddresses, address]);
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };
  const formatWalletAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};
  const toggleBanStatus = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const isCurrentlyBanned = await contract.isBanned(address);

      if (isCurrentlyBanned) {
        await contract.unbanAddress(address);
        console.log("Address unbanned:", address);
      } else {
        await contract.banAddress(address);
        console.log("Address banned:", address);
      }

      const updatedPending = [...pendingAddresses];
      const updatedApproved = [...approvedAddresses];

      if (isCurrentlyBanned) {
        updatedApproved.push(address);
        updatedPending.splice(updatedPending.indexOf(address), 1);
      } else {
        updatedApproved.splice(updatedApproved.indexOf(address), 1);
        updatedPending.push(address);
      }

      setPendingAddresses(updatedPending);
      setApprovedAddresses(updatedApproved);
    } catch (error) {
      console.error("Failed to toggle ban status:", error);
    }
  };
  const toggleRevokeStatus = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const isCurrentlyNotBanned = await contract.isAdmin(address);

      if (isCurrentlyNotBanned) {
        await contract.removeAdmin(address);
        console.log("Address unbanned:", address);
      } else {
        await contract.addAdmin(address);
        console.log("Address banned:", address);
      }

      const updatedrevoked = [...revokedAddresses];
      const updatednotrevoked = [...notrevokedAddresses];

      if (isCurrentlyNotBanned) {
       
        updatednotrevoked.splice(updatednotrevoked.indexOf(address), 1);
        updatedrevoked.push(address);
      } else {
        updatednotrevoked.push(address);
        updatedrevoked.splice(updatedrevoked.indexOf(address), 1);
       
      }

      setRevokedAddresses(updatedrevoked);
      setNotRevokedAddresses(updatednotrevoked);
    } catch (error) {
      console.error("Failed to toggle ban status:", error);
    }
  };

  const addAdmin = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      await contract.addAdmin(newAdminAddress);
      console.log("Admin added:", newAdminAddress);
      setSuccessMessage(`Admin ${newAdminAddress} added successfully!`);
      setNewAdminAddress('');
    } catch (error) {
      console.error("Failed to add admin:", error);
    }
  };

  const removeAdmin = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      await contract.removeAdmin(address);
      console.log("Admin removed:", address);

      setSuccessMessage(`Admin ${address} removed successfully!`);
    } catch (error) {
      console.error("Failed to remove admin:", error);
    }
  };

  return (
    <div className="min-h-screen text-white p-4 md:p-8">
    
    <div className="absolute top-4 sm:right-4 sm:top-2 right-1 text-white p-2 rounded-md">
    <button  >
               <w3m-button  balance='hide' />
              </button>
      </div>
      
      <h1 className="text-[24px] md:text-[36px] font-gilroy font-semibold mb-4 pt-8">Admin Panel</h1>
      {!currentAccount && (
        <div className="flex justify-center items-center min-h-screen">
          <button
           
            className="px-4 py-2 md:px-6 md:py-3 bg-[#FEF2D0] text-black rounded-[50px] hover:bg-[#C18F5B]"
          >
            <w3m-button/>
          </button>
          
        </div>
      )}
      
      {wallet && isAdmin && (
        <div className='gap-4'>
          <div className="mb-4 flex justify-center items-center">
            <input
              type="text"
              placeholder="Enter address"
              value={newAdminAddress}
              onChange={(e) => setNewAdminAddress(e.target.value)}
              className="bg-gray-800 w-[400px] text-white rounded-l-md py-2 px-4 mr-2 focus:outline-none"
            />
            <button
              onClick={addAdmin}
              className="bg-[#FEF2D0] hover:bg-[#C18F5B] text-black py-2 px-4 rounded-r-md"
            >
              Add Admin
            </button>

          </div>
          {successMessage && (
            <p className="text-green-500 mb-4">{successMessage}</p>
          )}
          {revokedAddresses.length === 0 && notrevokedAddresses.length === 0 ? (
            <p className="text-base md:text-xl">No addresses to display.</p>
          ) : (
            <div className='border-[#FEF2D0] border rounded-lg p-6'>
              <h2 className="text-[24px] md:text-[36px] font-gilroy font-semibold mb-4">Approved Admins</h2>
              <ul>
                {notrevokedAddresses.map((address, index) => (
                  <li
                    key={index}
                    className="mb-2 flex flex-col md:flex-row justify-between items-center bg-[#180E51] p-2 rounded-[10px]"
                  >
                    <div className="flex items-center mb-2 md:mb-0">
                      <span className="overflow-x-auto sm:text-[24px] text-[12px] mr-2">{address}</span>
                    </div>
                    <div className="flex flex-row">
                      <button
                        onClick={() => toggleRevokeStatus(address)}
                        className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded-md"
                      >
                        Revoke
                      </button>
                    </div>
                  </li>
                ))}
              </ul>

              <h2 className="text-[24px] md:text-[36px] font-gilroy font-semibold mb-4">Revoked Addmins</h2>
              <ul>
                {revokedAddresses.map((address, index) => (
                  <li
                    key={index}
                    className="mb-2 flex flex-col md:flex-row justify-between items-center bg-[#180E51] p-2 rounded-[10px]"
                  >
                    <div className="flex items-center mb-2 md:mb-0">
                      <span className="overflow-x-auto sm:text-[24px] text-[12px] mr-2">{address}</span>
                    </div>
                    <div className="flex flex-row">
                      <button
                        onClick={() => toggleRevokeStatus(address)}
                        className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md"
                      >
                        Add Admin
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {approvedAddresses.length === 0 && pendingAddresses.length === 0 ? (
            <p className="text-base md:text-xl">No addresses to display.</p>
          ) : (
            <div className='border-[#FEF2D0] border rounded-lg p-6 mt-10'>

              
              <h2 className="text-[24px] md:text-[36px] font-gilroy font-semibold mb-4">Approved Addresses</h2>
              <ul>
                {approvedAddresses.map((address, index) => (
                  <li
                    key={index}
                    className="mb-2 flex flex-col md:flex-row justify-between items-center bg-[#180E51] p-2 rounded-[10px]"
                  >
                    <div className="flex items-center mb-2 md:mb-0">
                      <span className="overflow-x-auto sm:text-[24px] text-[12px] mr-2">{address}</span>
                    </div>
                    <div className="flex flex-row">
                      <button
                        onClick={() => toggleBanStatus(address)}
                        className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded-md"
                      >
                        Ban
                      </button>
                    </div>
                  </li>
                ))}
              </ul>

              <h2 className="text-[24px] md:text-[36px] font-gilroy font-semibold mb-4">Banned Addresses</h2>
              <ul>
                {pendingAddresses.map((address, index) => (
                  <li
                    key={index}
                    className="mb-2 flex flex-col md:flex-row justify-between items-center bg-[#180E51] p-2 rounded-[10px]"
                  >
                    <div className="flex items-center mb-2 md:mb-0">
                      <span className="overflow-x-auto sm:text-[24px] text-[12px] mr-2">{address}</span>
                    </div>
                    <div className="flex flex-row">
                      <button
                        onClick={() => toggleBanStatus(address)}
                        className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md"
                      >
                        Unban
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      {currentAccount && !isAdmin && (
        <div className="flex justify-center items-center min-h-screen">
          <p className="text-base md:text-xl">You are not authorized to view this page.</p>
        </div>
      )}
    </div>
  );
};

export default AdminPage;