import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CoinFlip from './components/CoinFlip';
import OwnerPanel from './components/OwnerPanel';
import OwnerPanel2 from './components/OwnerPanel2';
import SpinWheel from './components/SpinWheel';
import Home from './components/Home';
import AdminPage from './components/admin';

function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul style={{ listStyleType: "none", display: "flex", justifyContent: "space-evenly" }}>
            <li>
              <Link to="/">CoinFlip</Link>
            </li>
            <li>
              <Link to="/owner">Owner Panel</Link>
            </li>
          </ul>
        </nav> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/coinflip" element={<CoinFlip />} />
          <Route path="/owner" element={<OwnerPanel />} />
          <Route path='/owner2' element={<OwnerPanel2 />} />
          <Route path='/spinwheel' element={<SpinWheel />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
