import React, { useState, useEffect } from 'react';
import './CoinFlip.css';
import { ethers } from 'ethers';
import { Link, useLocation } from 'react-router-dom';
import { Home } from 'lucide-react';
import Button from './Button';
import useWindowSize from "react-use/lib/useWindowSize";
import "animate.css";
import Confetti from "react-confetti";
import axios from 'axios';
import { Store } from 'react-notifications-component';
import 'reactjs-popup/dist/index.css';
import { LayoutDashboard,HandCoins,FerrisWheel,Gift,X,Menu } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useWeb3Modal, useWeb3ModalAccount,useWalletInfo,useSwitchNetwork,useWeb3ModalProvider } from '@web3modal/ethers5/react';

const contractABI = [
	{
		"inputs": [],
		"name": "adjustHouseBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "choice",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"name": "BetPlaced",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "win",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "payout",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"name": "BetResult",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "depositFunds",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FundsDeposited",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FundsWithdrawn",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_choice",
				"type": "bool"
			}
		],
		"name": "placeBet",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "getHouseBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getUserBets",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "choice",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "win",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "payout",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"internalType": "struct CoinFlip.Bet[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "houseBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumBet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userBets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "choice",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "win",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "payout",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const contractAddress = "0x26A90CCc53879f3466Dd1F6f970B25D9D62Ff7be";

const Telos = {
	chainId: '0x29', // Hexadecimal version of 80001, prefixed with 0x
	chainName: 'Telos EVM Testnet',
	nativeCurrency: {
	  name: 'Telos',
	  symbol: 'TLOS',
	  decimals: 18,
	},
	rpcUrls: ['https://testnet.telos.net/evm'],
	blockExplorerUrls: ['https://testnet.teloscan.io/'],
  };

function CoinFlip() {
	const web3Modal = useWeb3Modal();
  const wallet = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider()
 
  const [showSpinsLeftSidebar, setShowSpinsLeftSidebar] = useState(false);
  const switchNetwork = useSwitchNetwork();
	const { width, height } = useWindowSize();
	const [state ,setState]=useState('');
	const location = useLocation();
	const [betAmount, setBetAmount] = useState('');
	const [userBets, setUserBets] = useState([]);
	const [currentAccount, setCurrentAccount] = useState(null);
	const [houseBalance, setHouseBalance] = useState('');
	const [loading, setLoading] = useState(false);
	const [isFlipping, setIsFlipping] = useState(false);
	const [flipResult, setFlipResult] = useState('');
	const [showCoin,setShowCoin] = useState(true);
	const [shouldShowConfetti, setShouldShowConfetti] = useState(false);
	const [walletAddress, setWalletAddress] = useState(null);
	const [walletBalance, setWalletBalance] = useState('');
	const [shouldShowNextTime, setShouldShowNextTime] = useState(false);
	const [winAmount , setWinAmount] = useState('');
	const [Request,setRequest] = useState([]);
	const[betplaced,setBetplaced]=useState(false);
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const isMobile = useMediaQuery({ maxWidth: 768 });

	useEffect(() => {
		
		
		fetchHouseBalance();
	}, []);
	useEffect(() => {
		if (wallet) {
		  setCurrentAccount(wallet.address);
		  console.log(wallet)
		  fetchWalletBalance(wallet.address);
		  fetchUserBets()
	 
		 
		}
	  }, [wallet]);
	

	async function checkIfWalletIsConnected() {
		try {
			if (window.ethereum) {
				const accounts = await window.ethereum.request({ method: 'eth_accounts' });
				if (accounts.length > 0) {
					setCurrentAccount(accounts[0]);
					await switchToTelos();
					await fetchWalletBalance(accounts[0]); 
					await fetchUserBets(); 
				}
			} else {
				console.log("Ethereum object doesn't exist!");
			}
		} catch (error) {
			console.error("Failed to check if wallet is connected:", error);
		}
	}

	
		async function connectWallet() {
			try {
			  await web3Modal.open();
			     
			} catch (error) {
			  console.error("Failed to connect wallet:", error);
			}
		  }
	

	async function disconnectWallet() {
		setCurrentAccount(null);
		// Resetting application state
		setUserBets([]);
		setHouseBalance('');
	}
	async function requestAccount() {
		if (window.ethereum) {
		  console.log('detected');
		  try {
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			setWalletAddress(accounts[0]);
			// Fetch wallet balance after successfully connecting
			fetchWalletBalance(accounts[0]);
		  } catch (error) {
			console.error("Error connecting to MetaMask", error);
		  }
		} else {
		  alert('MetaMask not detected');
		}
	  }

	async function switchToTelos() {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Telos.chainId }],
            });
        } catch (error) {
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [Telos],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            } else {
                console.error(error);
            }
        }
    }

	async function fetchWalletBalance(account) {
		if (web3Modal) {
			const provider = new ethers.providers.Web3Provider(walletProvider);
			const balance = await provider.getBalance(account);
			const formattedBalance = ethers.utils.formatEther(balance);
			const walletBalance = String(formattedBalance).slice(0, 4) + '...';
			setWalletBalance(walletBalance);
		}
	}
	

	async function fetchHouseBalance() {
		if (web3Modal) {
			const provider = new ethers.providers.Web3Provider(walletProvider);
			const contract = new ethers.Contract(contractAddress, contractABI, provider);
			try {
				const balance = await contract.getHouseBalance();
				setHouseBalance(ethers.utils.formatEther(balance));
			} catch (error) {
				console.error('Error fetching house balance:', error);
			}
		}
	}

	async function fetchUserBets() {
		if (web3Modal ) {
			const provider = new ethers.providers.Web3Provider(walletProvider);
			const contract = new ethers.Contract(contractAddress, contractABI, provider);
			try {
				const address = await provider.getSigner().getAddress();
				const bets = await contract.getUserBets(currentAccount);
				console.log(bets); // Debug: Check if bets are fetched correctly
				const updatedBets = bets.map(bet => ({
					amount: ethers.utils.formatEther(bet.amount),
					choice: bet.choice ? "heads" : "tails",
					win: bet.win,
					payout: ethers.utils.formatEther(bet.payout),
					timestamp: new Date(bet.timestamp * 1000).toLocaleString()
				}));
				setUserBets(updatedBets);
				const latestBet = bets[bets.length - 1];
				console.log('betplaced',betplaced) // Assuming the latest bet is at the end of the array
			// 	if(betplaced){
			// 	if (latestBet && latestBet.win ) {
			// 		setWinAmount(ethers.utils.formatEther(latestBet.payout));
			// 		console.log(winAmount); 
			// 		setShouldShowConfetti(true);
					
					
			// 	}
			// 	else {
					
			// 		setShouldShowNextTime(true); // Show "Better Luck Next Time" message
			// 	}
			// }	setBetplaced(false)
				setUserBets(updatedBets);
			} catch (error) {
				console.error('Error fetching user bets:', error);
			}
		}
	}

	async function placeBet(choice) {
		setBetplaced(true);
		setShowCoin(true);
		console.log('placeBet', choice);
		if (!betAmount) return;
		if (web3Modal) {
			setLoading(true);
			setIsFlipping(true); // Start flipping
		
			const provider = new ethers.providers.Web3Provider(walletProvider);
			const signer = provider.getSigner();
			const contract = new ethers.Contract(contractAddress, contractABI, signer);
			try {
				// Simulate the flip result; this should ideally come from your smart contract event or function call result
				const simulatedResult = Math.random() > 0.5; // This is a placeholder for actual game logic
				setFlipResult(simulatedResult ? 'Heads' : 'Tails'); // Update flip result state
				setState(simulatedResult ? 'heads' : 'tails')
				const transactionResponse = await contract.placeBet(choice, { value: ethers.utils.parseEther(betAmount) });
				await transactionResponse.wait();
				setBetAmount('');
				fetchUserBets(); // Refresh the bet history after placing a bet
				fetchHouseBalance(); // Refresh house balance after a bet is placed
				fetchWalletBalance(currentAccount); // Refresh wallet balance after a bet is placed
				const bets = await contract.getUserBets(currentAccount);
				console.log(bets); // Debug: Check if bets are fetched correctly
				const updatedBets = bets.map(bet => ({
					amount: ethers.utils.formatEther(bet.amount),
					choice: bet.choice ? "heads" : "tails",
					win: bet.win,
					payout: ethers.utils.formatEther(bet.payout),
					timestamp: new Date(bet.timestamp * 1000).toLocaleString()
				}));
				setUserBets(updatedBets);
				const latestBet = bets[bets.length - 1];
				console.log('betplaced',betplaced) // Assuming the latest bet is at the end of the array
				if(betplaced){
				if (latestBet && latestBet.win ) {
					setWinAmount(ethers.utils.formatEther(latestBet.payout));
					console.log(winAmount); 
					setShouldShowConfetti(true);
					
					
				}
				else {
					
					setShouldShowNextTime(true); // Show "Better Luck Next Time" message
				}
			}
				
			} catch (error) {
				console.error('Error placing bet:', error);
				setErrorMessage("There was an error processing your transaction. Please try again.");
				setShowErrorPopup(true);
			} finally {
				setShowCoin(false);
				
				setLoading(false);
				setTimeout(() => setIsFlipping(false), 3000); // Stop flipping after some time (e.g., 3 seconds here)
			}
		}
	}

	const formatWalletAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};
	const removeScripts = () => {
        // Remove added script elements from the document's body when component unmounts
        const scriptElements = document.querySelectorAll('script[src^="js/"]');
        scriptElements.forEach(script => {
            document.body.removeChild(script);
        });
    };

	useEffect(() => {
		fetchUserBets();
		fetchHouseBalance();
	}, []);

	

	  async function fetchData(currentAccount) {
		try {
		  const response = await axios.get(`https://api.telos.net/v1/testnet/evmFaucet/${currentAccount}`);
		  return response.data;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  }
	
	  async function Faucet() {
		const data = await fetchData(currentAccount);
		if (data) {
		  setRequest(data);
		  Store.addNotification({
			title: 'Success!',
			message: "50 Telos added successfully",
			type: "success",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
			  duration: 5000,
			  onScreen: true
			},
			slidingExit: {
				duration: 800,
				timingFunction: 'ease-out',
				delay: 0
			  },
			width: 500,
		  });
		  console.log(data);
		}else{
			Store.addNotification({
			title: "Error",
			message: "IP or account has recieved faucet funds within the last 24hrs",
			type: "danger",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
			  duration: 5000,
			  onScreen: true
			},
			slidingExit: {
				duration: 800,
				timingFunction: 'ease-out',
				delay: 0
			  },
			width: 500,
		  });
		}
	  }
	 

	 

	return (
// 		<div className='flex justify-start flex-col overscroll-y-none overflow-clip '>
// 			    {shouldShowConfetti &&
// 				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				  <p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
// 				  <p className='text-white font-lufga font-bold text-[24px]'>You won {winAmount} Telos</p>
// 				  <div onClick={() => setShouldShowConfetti(false)}>
// 					<Button text='close'></Button>
// 				  </div>
// 				</div>
// 			  </div>
			  
// 				}
// 				{showErrorPopup && (
//     <div style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: 'rgba(0,0,0,0.5)',
//         zIndex: 100
//     }} onClick={() => setShowErrorPopup(false)}>
//         <div style={{
//             margin: '15% auto',
//             backgroundColor: '#fff',
//             padding: '20px',
//             borderRadius: '5px',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//             maxWidth: '500px',
//             textAlign: 'center',
//         }} onClick={(e) => e.stopPropagation()}>
//             <h2>Error</h2>
//             <p>{errorMessage}</p>
//             <button style={{
//                 marginTop: '15px',
//                 padding: '10px 20px',
//                 backgroundColor: '#007bff',
//                 color: 'white',
//                 border: 'none',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//             }} onClick={() => setShowErrorPopup(false)}>Close</button>
//         </div>
//     </div>
// )}
// 				{shouldShowNextTime&& 
// 				 <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				 <div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				   <p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
// 				   <img src={'/lost.gif'} />
// 				   <div onClick={() => setShouldShowNextTime(false)}>
// 					 <Button text='close'></Button>
// 				   </div>
// 				 </div>
// 			   </div>
// 				}
				
// 			<div className='flex justify-between p-8 gap-20  items-center flex-row'>
// 				<p className='text-white font-lufga font-bold sm:text-[32px] text-[14px] '>CoinFlip</p>
// 				<div className={`  flex-row  sm:flex content-center gap-6 bg-${location.pathname === '/' ? '[linear-gradient(_101deg,_#1f8ebe_0%,_#440495_39%,_#440495_69%,_#b102cd_98%_)] rounded-[16px] ' : 'black'} rounded-xl p-2  justify-center items center`}>
					
// 					<Link to='/' className={`text-white font-lufga font-bold text-[16px] `}>Home</Link>
// 					<Link to='/spinwheel' className={`text-white font-lufga font-bold text-[16px] `}>Spinwheel</Link>
// 				</div>
				
// 				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
// 					{currentAccount ? (
// 						<div className='flex sm:flex-row flex-col gap-4'>
					
							
			
							
// 							<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl sm:text-[16px] text-[12px] font-lufga bg-white' onClick={disconnectWallet}><span>{formatWalletAddress(currentAccount)}</span></button>
// 							<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl text-black sm:text-[16px] text-[12px] font-lufga bg-white' onClick={Faucet} >
// 								Faucet
// 							</button>
// 						</div>
// 					) : (
						
						
// 						<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl sm:text-[16px] text-[12px] font-lufga bg-white' onClick={connectWallet}>Connect Wallet</button>
						
// 					)}
// 				</div>
// 			</div>
// 			<div className='flex flex-col w-screen gap-8 justify-start sm:pl-20 sm:pr-20 sm:pt-4 sm:pb-4  pl-8 pr-8 pt-2 pb-2 '>
			
					
					
						
// 			<div class=" font-lufga text-white flex justify-start font-regular text-[32px] ">Home</div>
						
						
						
						
					
				
// 				<div className='flex justify-center flex-col h-[800px]  sm:rounded-[100px] rounded-[50px] items-center  bg-gradient-to-r from-pink-500 via-red-500 to-purple-600 p-1' >
// 					<div className='flex justify-evenly sm:flex-row flex-col items-center w-full h-full sm:rounded-[100px] rounded-[50px] gap-10 bg-black p-4'>
// 						<div className='flex justify-center flex-col sm:h-[600px] sm:w-[400px] h-[80px] w-full sm:rounded-[100px] rounded-[50px] items-center  bg-gradient-to-r from-pink-500 via-red-500 to-purple-600 p-1'>
							
// 								<h1 className='text-white font-lufga font-bold sm:text-[40px] text-[20px]'>House Balance </h1>
// 								<div className='sm:p-4 p-2 text-white font-lufga font-regular sm:text-[24px] text-[12px] rounded-xl bg-black border border-white'>{houseBalance} Telos</div>
							
// 						</div>
// 						<div className='flex flex-col justify-center gap-4 items-center'>
// 					<h2 className='text-white  font-lufga font-bold sm:text-[46px] text-[23px]'>Place Your Bet</h2>
					
					
// 					{showCoin&& <div className="coin-animation text-white">
// 						<div id="coin" className={`${state} ${isFlipping ? 'spin' : ''}`} key={+new Date()}>
// 							<div class="side-a">
// 								<img className="coin-image"  src='/coin1.png' />
// 							</div>
// 							<div className="side-b">
// 							<img className="coin-image"  src='/coin2.png' />
// 							</div>
// 						</div> </div>}
// 					{!isFlipping && flipResult &&!showCoin&& <div className="coin-result text-white">
						
// 						{flipResult === 'Heads' ? <img  className="coin-image"  src='/coin1.png' /> : <img  className="coin-image"  src='/coin2.png' />
// 							}
// 						</div>}
// 					<input className='bg-[#d9d9d97c] p-4 rounded-xl sm:w-[600px] sm:h-[70px] w-full h-[50px] text-white font-lufga font-bold text-[24px]'
// 						type="text"
// 						placeholder="Bet Amount in Telos"
// 						value={betAmount}
// 						onChange={(e) => setBetAmount(e.target.value)}
// 						disabled={loading}
// 					/>
// 					<div className='flex flex-row justify-center items-center gap-2'>
// 					<p className='text-white  font-lufga font-regular sm:text-[18px] text-[12px]'>Heads = Ethereum</p>
// 					<p className='text-white  font-lufga font-regular sm:text-[18px] text-[12px]'>Tails = Bitcoin</p>
// 					</div>
// 					<div className='flex sm:flex-row flex-col sm:gap-10 gap-3'>
// 						<div onClick={() => placeBet(true)} disabled={loading}>
// 						<Button text='Bet on heads'  ></Button>
// 						</div>
// 						<div  onClick={() => placeBet(false)} disabled={loading} >
// 						<Button text='Bet on tails'  ></Button>
// 						</div>
						
					
// 					</div>
					
					
					

// 					</div>
// 					<div className='flex justify-center flex-col sm:h-[600px] sm:w-[400px] h-[80px] w-full sm:rounded-[100px]  rounded-[100px] items-center  bg-gradient-to-r from-pink-500 via-red-500 to-purple-600 p-1'>
							
// 								<h1 className='text-white font-lufga font-bold sm:text-[40px] text-[20px]'>Your Balance </h1>
// 								<div className='sm:p-4 p-2 text-white font-lufga font-regular sm:text-[24px] text-[12px] rounded-xl bg-black border border-white'>{walletBalance} Telos</div>
							
// 						</div>
// 					</div>
// 				</div>
// 				<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[100px] items-center border-white border sm:p-10 p-4'>
// 				<h3 className='text-white  font-lufga font-bold text-[24px]'>Your Bets</h3>
// 					<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
// 						{userBets.map((bet, index) => (
// 							<div className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px] '>
// 							<li key={index}>Amount: {bet.amount} Telos</li>
// 							<li key={index}>Choice: {bet.choice}</li>
// 							<li key={index}>Win: {bet.win ? 'Yes' : 'No'}</li>
// 							<li key={index}>Payout: {bet.payout} Telos</li>
// 							<li key={index}>Time: {bet.timestamp}</li>
							
// 							</div>
// 						))}
// 					</ul>
// 				</div>
// 			</div>
// 		</div>
		<>
		{!isMobile&&(
			<div className='flex flex-row h-screen'>
			{shouldShowConfetti &&
							<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
							<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
							<p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
							<p className='text-white font-lufga font-bold text-[24px]'>You won {winAmount} Telos</p>
							<div onClick={() => setShouldShowConfetti(false)}>
								<Button text='close'></Button>
							</div>
							</div>
						</div>
						
							}
							{showErrorPopup && (
				<div style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0,0,0,0.5)',
					zIndex: 100
				}} onClick={() => setShowErrorPopup(false)}>
					<div style={{
						margin: '15% auto',
						backgroundColor: '#fff',
						padding: '20px',
						borderRadius: '5px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
						maxWidth: '500px',
						textAlign: 'center',
					}} onClick={(e) => e.stopPropagation()}>
						<h2>Error</h2>
						<p>{errorMessage}</p>
						<button style={{
							marginTop: '15px',
							padding: '10px 20px',
							backgroundColor: '#007bff',
							color: 'white',
							border: 'none',
							borderRadius: '5px',
							cursor: 'pointer',
						}} onClick={() => setShowErrorPopup(false)}>Close</button>
					</div>
				</div>
			)}
							{shouldShowNextTime&& 
							<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
							<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
							<p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
							<img src={'/lost.gif'} />
							<div onClick={() => setShouldShowNextTime(false)}>
								<Button text='close'></Button>
							</div>
							</div>
						</div>
							}
					{/* <div className='w-1/5 pt-12 pl-8 pr-8 pb-12 justify-stretch relative'>
						<div>
						<h1 className='text-[36px] font-gilroy font-semibold text-white'>TelosCasino</h1>
						</div>
					
					<div className='gap-6 pl-[20%] pt-20 flex flex-col items-start justify-center'>
						<div className='flex flex-row gap-2 text-white items-center'><LayoutDashboard/>  <Link to='/' className='text-[22px] font-gilroy font-medium '>DASHBOARD</Link></div>
						<div className='flex flex-row gap-2 text-[#FEF2D0] items-center'><HandCoins/>  <Link to='/coinflip' className='text-[22px] font-gilroy font-medium text-white'style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>COINFLIP</Link></div>
						<div className='flex flex-row gap-2 text-white items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[22px] font-gilroy font-medium text-white'>SPINWHEEL</Link></div>
					
						<div className="absolute left-[-50%] bottom-9 -mb-8 h-[500px] w-[450px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
						<div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
						</div>
		
		
			
		
					</div>
					</div> */}
					<div 
      className={`fixed top-0 left-0 z-10 flex items-start justify-start transition-all duration-300 ease-in-out`}
      style={{ 
        height: '100vh',
        width: showSpinsLeftSidebar ? '400px' : '80px',
        transform: showSpinsLeftSidebar ? 'translateX(0)' : 'translateX(0)',
      }}
    >
      <button 
        className={`flex items-start pt-[50px] justify-center w-20 h-[100%] text-[32px] border-[#FEF2D0] border-r  shadow-lg transition-all duration-300 ease-in-out bg-[#211c55]`}
        onClick={() => setShowSpinsLeftSidebar(!showSpinsLeftSidebar)}
        style={{
          position: 'absolute',
          left: showSpinsLeftSidebar ? '320px' : '0',
          right: showSpinsLeftSidebar ? 'auto' : '0',
        }}
      >
        {showSpinsLeftSidebar ? (
          <X className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        ) : (
          <Menu className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        )}
      </button>

      <div 
        className="bg-[#211c55] text-[#FEF2D0] border-[#FEF2D0] border-r font-gilroy font-medium shadow-lg transition-all duration-300 ease-in-out"
        style={{ 
          width: showSpinsLeftSidebar ? '100%' : '0', 
          height: '100%',
          overflow: 'hidden',
          opacity: showSpinsLeftSidebar ? 1 : 0,
          visibility: showSpinsLeftSidebar ? 'visible' : 'hidden',
          borderRight: '2px solid #FEF2D0',
        }}
      >
        <div className="flex flex-col items-center gap-10 justify-center h-full p-10">
        <div className='flex flex-row gap-2 text-white items-center'><LayoutDashboard/>  <Link to='/' className='text-[28px] font-gilroy font-medium '>DASHBOARD</Link></div>
				<div className='flex flex-row gap-2 text-white items-center'><HandCoins/>  <Link to='/coinflip' className='text-[28px] font-gilroy font-medium text-white'style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>COINFLIP</Link></div>
				<div className='flex flex-row gap-2 text-[#FEF2D0]  items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[28px] font-gilroy font-medium text-white' >SPINWHEEL</Link></div>
			
        </div>
      </div>
    </div>
					<div className='flex-grow flex-col pt-8 pl-32 pr-10 justify-between '>
						<div className='flex justify-between font-gilroy font-medium relative p-2 items-center'>
						<div className="absolute top-0 right-0 -z-10 -mt-9 mr-9 h-[250px] w-[350px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
				<div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
			</div>
						<button className='pt-4 pb-4 pl-6 pr-6 text-[20px] border  border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'><Gift className="text-[#FFDB93]"/>Rewards</button>
						<div className='flex flex-row gap-2'>
						<button className='p-2 pl-4 text-20px border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
						<img src='/telos.svg' width={22} height={22} />{walletBalance}
						<button className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
							Balance
						</button>
						</button>
						<button  onClick={Faucet}  className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 font-gilroy font-bold items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
							Faucet
						</button>
						</div>
		
						
					 <button >
						<w3m-button/>
						</button>
						
						
						</div>
						<div className='border rounded-[50px] mt-4 flex flex-col pt-20 pb-20 pl-20 pr-20 justify-center items-center  border-[#FEF2D0] ' >
						<div className='flex flex-row h-[600px] rounded-[20px] w-full gap-8 justify-between bg-[#141135] '>
							<div className='flex flex-col justify-start  rounded-l-[20px] items-start gap-4 bg-[#211c55] pt-10 pl-4 pr-4'>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Enter the Bet Amount</p>
							<input className='bg-[#141135] p-4 rounded-xl sm:w-[400px] sm:h-[70px] w-full h-[50px] text-white font-lufga font-bold text-[24px]'
									type="text"
									placeholder="Bet Amount in Telos"
									value={betAmount}
									onChange={(e) => setBetAmount(e.target.value)}
									disabled={loading}
								/>
								<div className='flex flex-row justify-center items-center gap-2 p-2'>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Heads = Ethereum</p>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Tails = Bitcoin</p>
								</div>
								<button  disabled={loading} className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 w-full mb-4 font-gilroy font-medium text-[20px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
									Coming Soon
								</button>
								{/* <button onClick={() => placeBet(false)} disabled={loading} className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 w-full font-gilroy font-medium text-[20px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
									Bet on tails
								</button> */}
								
								
							</div>
							<div className='flex flex-col p-10 '>
							{showCoin&& <div className="coin-animation text-white">
									<div id="coin" className={`${state} ${isFlipping ? 'spin' : ''}`} key={+new Date()}>
										<div class="side-a">
											<img className="coin-image"  src='/coin1.png' />
										</div>
										<div className="side-b">
										<img className="coin-image"  src='/coin2.png' />
										</div> 						</div> </div>}
							{!isFlipping && flipResult &&!showCoin&& <div className="coin-result text-white">
									
								{flipResult === 'Heads' ? <img  className="coin-image"  src='/coin1.png' /> : <img  className="coin-image"  src='/coin2.png' />
										}
									</div>}
							</div>
							<div>
		
							</div>
						</div>
						
						</div>
						<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[50px] mt-8 items-center border-white border sm:p-10 p-4'>
							<h3 className='text-white font-lufga font-bold text-[24px]'>Your Bets</h3>
								<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
								{userBets.map((bet, index) => (
										<div className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px] '>
										<li key={index}>Amount: {bet.amount} Telos</li>
										<li key={index}>Choice: {bet.choice}</li>
										<li key={index}>Win: {bet.win ? 'Yes' : 'No'}</li>
										<li key={index}>Payout: {bet.payout} Telos</li>
										<li key={index}>Time: {bet.timestamp}</li>
										
										</div>
									))}
								</ul>
							</div>
		
					</div>
				</div>
		) }
		{isMobile&&(
			<div className='flex flex-col  h-screen'>
				{shouldShowConfetti &&
							<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
							<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
							<p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
							<p className='text-white font-lufga font-bold text-[24px]'>You won {winAmount} Telos</p>
							<div onClick={() => setShouldShowConfetti(false)}>
								<Button text='close'></Button>
							</div>
							</div>
						</div>
						
							}
							{showErrorPopup && (
				<div style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0,0,0,0.5)',
					zIndex: 100
				}} onClick={() => setShowErrorPopup(false)}>
					<div style={{
						margin: '15% auto',
						backgroundColor: '#fff',
						padding: '20px',
						borderRadius: '5px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
						maxWidth: '500px',
						textAlign: 'center',
					}} onClick={(e) => e.stopPropagation()}>
						<h2>Error</h2>
						<p>{errorMessage}</p>
						<button style={{
							marginTop: '15px',
							padding: '10px 20px',
							backgroundColor: '#007bff',
							color: 'white',
							border: 'none',
							borderRadius: '5px',
							cursor: 'pointer',
						}} onClick={() => setShowErrorPopup(false)}>Close</button>
					</div>
				</div>
			)}
							{shouldShowNextTime&& 
							<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
							<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
							<p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
							<img src={'/lost.gif'} />
							<div onClick={() => setShouldShowNextTime(false)}>
								<Button text='close'></Button>
							</div>
							</div>
						</div>
							}
			<div className='flex flex-row pt-8 pl-2 pr-2 pb-4 justify-evenly  relative'>
				
				<h1 className='text-[16px] font-gilroy font-semibold text-white'>TelosCasino</h1>
				<button className='pt-1 pb-1 pr-3 pl-3 text-[10px] font-gilroy border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
				  <img src='/telos.svg' width={16} height={16} />{walletBalance}
				  <button className=' flex flex-row justify-center pl-1 pr-1 pt-1 pb-1 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Balance
				  </button>
				</button>
				<button  onClick={Faucet}  className=' flex flex-row justify-center pl-2 pr-2 pt-1 pb-1 font-gilroy text-[12px] font-medium items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
							Faucet
						</button>
	
	
				<button  onClick={connectWallet} className=' flex flex-row justify-center font-gilroy text-[12px] pl-2 pr-2 pt-1 pb-1 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
				   {currentAccount?formatWalletAddress(currentAccount).slice(0, 3): 'Connect'}
				  </button>
			   
			   
			</div>
			<div className='border rounded-[20px] h-screen  flex flex-col p-2  border-[#FEF2D0] ' >
			<div className='flex flex-col p-10 '>
							{showCoin&& <div className="coin-animation text-white">
									<div id="coin" className={`${state} ${isFlipping ? 'spin' : ''}`} key={+new Date()}>
										<div class="side-a">
											<img className="coin-image"  src='/coin1.png' />
										</div>
										<div className="side-b">
										<img className="coin-image"  src='/coin2.png' />
										</div> 						</div> </div>}
							{!isFlipping && flipResult &&!showCoin&& <div className="coin-result text-white">
									
								{flipResult === 'Heads' ? <img  className="coin-image"  src='/coin1.png' /> : <img  className="coin-image"  src='/coin2.png' />
										}
									</div>}
							</div>
				  <div className='flex flex-col bg-[#180E51]'>
				  <div className='flex flex-col justify-start  rounded-b-[20px] items-start gap-2 bg-[#211c55] pt-5 pl-2 pr-2 pb-5'>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Enter the Bet Amount</p>
							<input className='bg-[#141135] p-4 rounded-xl sm:w-[400px] sm:h-[70px] w-full h-[50px] text-white font-lufga font-bold text-[24px]'
									type="text"
									placeholder="Bet Amount in Telos"
									value={betAmount}
									onChange={(e) => setBetAmount(e.target.value)}
									disabled={loading}
								/>
								<div className='flex flex-row justify-center items-center gap-2 p-2'>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Heads = Ethereum</p>
								<p className='text-white  font-gilroy font-regular sm:text-[18px] text-[12px]'>Tails = Bitcoin</p>
								</div>
								<button onClick={() => placeBet(true)} disabled={loading} className=' flex flex-row justify-center pl-3 pr-3 pt-2 pb-2 w-full mb-4 font-gilroy font-medium text-[14px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
									Bet on heads
								</button>
								<button onClick={() => placeBet(false)} disabled={loading} className=' flex flex-row justify-center pl-3 pr-3 pt-2 pb-2 w-full font-gilroy font-medium text-[14px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
									Bet on tails
								</button>
								
								
							</div>
							
				  </div>
				  {userBets.length > 0 &&(<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[20px] mt-20 items-center border-[#FEF2D0] border sm:p-10 p-4'>
							<h3 className='text-white font-lufga font-bold text-[24px]'>Your Bets</h3>
								<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
								{userBets.map((bet, index) => (
										<div className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px] '>
										<li key={index}>Amount: {bet.amount} Telos</li>
										<li key={index}>Choice: {bet.choice}</li>
										<li key={index}>Win: {bet.win ? 'Yes' : 'No'}</li>
										<li key={index}>Payout: {bet.payout} Telos</li>
										<li key={index}>Time: {bet.timestamp}</li>
										
										</div>
									))}
								</ul>
							</div>)}
				  
				  
				</div>
		  </div>
		)}
		</>
	);
}


export default CoinFlip;