import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { Link, useLocation } from 'react-router-dom';
import { Wheel } from "../components/Wheel/Wheel";
import { Store } from 'react-notifications-component';
import axios from 'axios';
import { getRandomInt } from "../utils";
import Button from './Button';
import { ChevronLeft,ChevronRight,X,Menu } from 'lucide-react';
import './Goldtext.css'
import { LayoutDashboard,HandCoins,FerrisWheel,Gift,BadgeInfoIcon } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useWeb3Modal, useWeb3ModalAccount,useWalletInfo,useSwitchNetwork,useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { useNetworkMismatch,useSwitchChain } from "@thirdweb-dev/react";
import {TelosEvmTestnet} from '@thirdweb-dev/chains'
const contractAddress = '0xBa5fcca20B070221661076A62cC225071DDd78ee';

const data = [
	{ option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
	{ option: '1', style: { backgroundColor: 'white' } },
	{ option: '2' },
  ]
const contractABI = [
	{
		"inputs": [],
		"name": "addLiquidity",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "LiquidityAdded",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "LiquidityRemoved",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "removeLiquidity",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "spinWheel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "reward",
				"type": "uint256"
			}
		],
		"name": "Spun",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "lastResetTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "spinsRemaining",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalLiquidity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];


function SpinWheel() {
	const location = useLocation();
	const [mustSpin, setMustSpin] = useState(false);
    const [provider, setProvider] = useState(null);
    const [contract, setContract] = useState(null);
	const [walletBalance, setWalletBalance] = useState('');
    const [isSpinning, setIsSpinning] = useState(false);
    const [spinResult, setSpinResult] = useState(null);
    const [userHistory, setUserHistory] = useState([]);
    const [currentAccount, setCurrentAccount] = useState(null);
	const [viewWheel ,setViewWheel] = useState(true);
	const [shouldShowConfetti, setShouldShowConfetti] = useState(false);
	const secondWheelRef = useRef(null);
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [Request,setRequest] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [shouldShowNextTime, setShouldShowNextTime] = useState(false);
	const [couponNum, setCouponNum] = useState(1);
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const [showSpinsRightSidebar, setShowSpinsRightbar] = useState(false);
	const web3Modal = useWeb3Modal();
  const wallet = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider()
  const [transaction, setTransaction] = useState(null);
  const [showSpinsLeft, setShowSpinsLeft] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [lastSpinTimestamp, setLastSpinTimestamp] = useState(null);
  const spinCooldown = 24 * 60 * 60 * 1000;
  const [timeLeft, setTimeLeft] = useState(null);
  const intervalRef = useRef(null);
  const switchNetwork = useSwitchNetwork();
  const [totalSpinsRemaining, setTotalSpinsRemaining] = useState(0);
  const [expandInstruction, setExpandInstruction] = useState(false)
  const [showSpinsLeftSidebar, setShowSpinsLeftSidebar] = useState(false);
  const isMismatched = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const [open, setOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [rewardsOpen, setRewardsOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Telos = {
	chainId: '0x29', // Hexadecimal version of 80001, prefixed with 0x
	chainName: 'Telos EVM Testnet',
	nativeCurrency: {
	  name: 'Telos',
	  symbol: 'TLOS',
	  decimals: 18,
	},
	rpcUrls: ['https://testnet.telos.net/evm'],
	blockExplorerUrls: ['https://testnet.teloscan.io/'],
  }

  const mockData = {
    1: "0",
    2: "0.5",
    3: "0.25",
    4: "0.75",
    5: "1",
    6: "2",
    7: "5",
  };

	const segments = [
		"0",
		"0.5",
		"0.25",
		"0.75",
		"1",
		"2",
		"5"
	  ];
	const rewards = [0, 0.5, 0.25, 0.75, 1, 2, 5];
	const [resultString, setResultString] = useState(-1);
    const wheelRef = useRef(null);
	const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F","#34B24F"];
	const [isLoading, setIsLoading] = useState(false);
	const [spinCount, setSpinCount] = useState(0); 

  useEffect(() => {
    // Hide loading screen after 10 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
	const savedTimestamp = localStorage.getItem('lastSpinTimestamp');
	if (savedTimestamp) {
		setLastSpinTimestamp(new Date(savedTimestamp));
	}
}, []);
useEffect(() => {
	if (lastSpinTimestamp) {
		const updateTimer = () => {
			const now = new Date();
			const timeDifference = lastSpinTimestamp.getTime() + spinCooldown - now.getTime();
			if (timeDifference <= 0) {
				clearInterval(intervalRef.current);
				setTimeLeft(null);
				localStorage.removeItem('lastSpinTimestamp');
			} else {
				const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
				setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
			}
		};

		updateTimer(); // Update immediately
		intervalRef.current = setInterval(updateTimer, 1000);

		return () => clearInterval(intervalRef.current);
	}
}, [lastSpinTimestamp]);
  useEffect(() => {
	
	if (wallet) {
	  setCurrentAccount(wallet.address);
	  console.log(wallet)

	  fetchWalletBalance(wallet.address);
	  fetchUserHistory();
	  
 
	 
	}
  }, [wallet]);
    
	const onFinished = (winner) => {
		console.log(winner);
	  };

	function connectWalletHandler()  {
		if (web3Modal) {
			try {
				console.log('contract connected')
				console.log(currentAccount)
				// Instantiate a new provider
				const tempProvider = new ethers.providers.Web3Provider(walletProvider);
				// Use the provider immediately to create the contract instance
				const tempContract = new ethers.Contract(contractAddress, contractABI, tempProvider.getSigner(currentAccount));
				// Then update the state
				setProvider(tempProvider);
				setCurrentAccount(currentAccount);
				setContract(tempContract);
				
				fetchUserHistory();
			} catch (error) {
				console.error("Could not get accounts", error);
			}
		}
	};
	async function fetchWalletBalance(account) {
		if (wallet) {
			const provider = new ethers.providers.Web3Provider(walletProvider);
			const balance = await provider.getBalance(account);
			const formattedBalance = ethers.utils.formatEther(balance);
			const walletBalance = String(formattedBalance).slice(0, 8) + '...';
			setWalletBalance(walletBalance);
			
				
		}
	}
	


	async function fetchData(currentAccount) {
		try {
		  const response = await axios.get(`https://api.telos.net/v1/testnet/evmFaucet/${currentAccount}`);
		  return response.data;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  }
	async function Faucet() {
		const data = await fetchData(currentAccount);
		await fetchWalletBalance(wallet.address)

		if (data) {
		  setRequest(data);
		  Store.addNotification({
			title: 'Success!',
			message: "50 Telos added successfully",
			type: "success",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
			  duration: 5000,
			  onScreen: true
			},
			slidingExit: {
				duration: 800,
				timingFunction: 'ease-out',
				delay: 0
			  },
			width: 500,
		  });
		  console.log(data);
		}else{
			Store.addNotification({
			title: "Error",
			message: "IP or account has recieved faucet funds within the last 24hrs",
			type: "danger",
			insert: "top",
			container: "top-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
			  duration: 5000,
			  onScreen: true
			},
			slidingExit: {
				duration: 800,
				timingFunction: 'ease-out',
				delay: 0
			  },
			width: 500,
		  });
		}
	  }


	const disconnectWalletHandler = () => {
        // Clear the account state, effectively "disconnecting" the wallet
        setCurrentAccount(null);
        console.log("Wallet disconnected. Please reconnect to continue.");
    };
    useEffect(() => {
		secondWheelRef.current = document.getElementById('secondWheel');
	}, []);
useEffect(() => {
	connectWalletHandler()},[currentAccount])
	useEffect(() => {
		async function fetchTotalSpinsRemaining() {
		  if (contract) {
			try {
			  const totalSpins = await contract.spinsRemaining();
			  setTotalSpinsRemaining(totalSpins.toNumber());
			} catch (error) {
			  console.error('Error fetching total spins remaining:', error);
			}
		  }
		}
	  
		fetchTotalSpinsRemaining();
	  }, [contract]);
	
	useEffect(() => {
		console.log('resultString updated:', resultString, viewWheel, secondWheelRef.current);
	}, [resultString]);	
	useEffect(() => {
		if (!viewWheel && secondWheelRef.current) {
			const clickEvent = new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			});
			secondWheelRef.current.dispatchEvent(clickEvent);
		}
	}, [viewWheel]);
	const formatWalletAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};
	
	const connectWallet = async () => {
		try {
		  // Open the Web3 modal to connect the wallet
		  await web3Modal.open();
		  console.log('Connecting to wallet...');
	
		  // After the wallet is connected, get the provider
	
		setCurrentAccount(wallet.address)
		console.log(currentAccount)
		  // Get the list of accounts
		  
	
		  if (currentAccount) {
			
		

			
	
			// Create a signer and the contract instance
			const tempProvider = new ethers.providers.Web3Provider(walletProvider);
			// Use the provider immediately to create the contract instance
			const tempContract = new ethers.Contract(contractAddress, contractABI, tempProvider.getSigner(currentAccount));
			// Then update the state
			setProvider(tempProvider);
			setCurrentAccount(currentAccount);
			setContract(tempContract);
	
	
			console.log('Contract setup complete:', contract);
		  } else {
			console.error("No accounts found.");
		  }
		} catch (error) {
		  console.error("Failed to connect wallet:", error);
		}
	  };
	
	async function switchToTelos() {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Telos.chainId }],
            });
        } catch (error) {
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [Telos],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            } else {
                console.error(error);
            }
        }
    }
	async function disconnectWallet() {
		setCurrentAccount(null);
		
	}

    const spinWheel = async () => {
		if(isMismatched){
			await switchChain(TelosEvmTestnet.chainId)
		  }
		const now = new Date();
        if (lastSpinTimestamp && now - lastSpinTimestamp < spinCooldown) {
            const timeLeft = new Date(lastSpinTimestamp.getTime() + spinCooldown - now);
            const hoursLeft = timeLeft.getUTCHours();
            const minutesLeft = timeLeft.getUTCMinutes();

            Store.addNotification({
                title: "Cooldown Period",
                message: `Please wait ${hoursLeft} hours and ${minutesLeft} minutes before spinning again.You can only spin once per device per day`,
                type: "warning",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
                slidingExit: {
                    duration: 800,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                width: 500,
            });

            return;
        }
		if(wallet.isConnected === false){
			Store.addNotification({
                title: "Please Connect Wallet",
                message: `Connect your wallet in order to spin the wheel`,
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
                slidingExit: {
                    duration: 800,
                    timingFunction: 'ease-out',
                    delay: 0
                },
                width: 500,
            });

            return;
        
		}

		connectWalletHandler()
		
		console.log('start')
		console.log('contact',contract)
        if (!contract || isSpinning) return;
		
        setIsSpinning(true);

        try {
           

        const tx = await contract.spinWheel();

			
            const receipt = await tx.wait();

            const spinResultEvent = receipt.events?.find(e => e.event === "Spun");
            const result = spinResultEvent ? spinResultEvent.args.reward.toString() : null;
            setSpinResult(ethers.utils.formatEther(result));

            // Animation logic
            const segmentDegrees = 360 / 7; // Adjust based on your wheel design
			
            const resultIndex = rewards.indexOf(parseFloat(ethers.utils.formatEther(result)));
			setResultString(resultIndex);
			const resultNumber = rewards[resultIndex]; // Get the number from the array using the index
			const resultstring = resultNumber.toString(); // Convert the number to a string
			
			console.log('resultString:', resultIndex);
            const extraSpins = 360 * 5; // 5 extra spins for effect
            const finalPosition = extraSpins + (resultIndex * segmentDegrees);
           
			setViewWheel(false)
			
			setSpinCount(spinCount + 1); 
				
			  
			setTimeout(() =>{
				if (!spinning && !cancelled) {
					console.log('cancelled',cancelled)
					setSpinning(true);
					const newCouponNum = resultIndex+1
					console.log('newCouponNum:', newCouponNum)
					setCouponNum(newCouponNum);
					console.log(newCouponNum);
					setMustSpin(true);
				  }
			},4000)
			
                
				
            // Wait for the animation to end before resetting
            setTimeout(() => {
                if(!cancelled){
					if(resultstring==='0'){
						setShouldShowNextTime(true);
					}else setShouldShowConfetti(true);
					
				}
				const now = new Date();
            setLastSpinTimestamp(now);
            localStorage.setItem('lastSpinTimestamp', now);
				
				
				
            }, 9000);
            setTimeout(() => {
                setIsSpinning(false);
				setCancelled(false)
                fetchUserHistory(); // Refresh user history after spin
			
				
				
				
				
            }, 4000);
			const totalSpins = await contract.spinsRemaining();
    		setTotalSpinsRemaining(totalSpins.toNumber());
            
            
        } catch (error) {
			setErrorMessage("There was an error processing your transaction. Please try again.");
            console.error("Error spinning the wheel:", error);
            setIsSpinning(false);
        }
		
    };
	
// 	useEffect(() => {
// 		if(!isSpinning){
// 		setTimeout(() => {
// 		window.buttonSpin.addEventListener("click", function(evt) {
// 			//getResult(1,0);
			
// 			spinWheel()
// 		});
// 	}, 5000);
// }
// 	})
	const viewReal = (resultstring) => {
        
        if (secondWheelRef.current) {
			console.log("second wheel not null",secondWheelRef.current);
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            secondWheelRef.current.dispatchEvent(clickEvent);
        }
    };
	const reset=()=>{
		setShouldShowConfetti(false);
		setShouldShowNextTime(false);
		setViewWheel(true);
	
	}

	const fetchUserHistory = async () => {
		if (!currentAccount) return;
		try {
			const filter = contract.filters.Spun(currentAccount);
			const events = await contract.queryFilter(filter);

			const historyPromises = events.map(async (event) => {
				const block = await provider.getBlock(event.blockNumber);

				return {
					time: new Date(block.timestamp * 1000),
					result: ethers.utils.formatEther(event.args.reward.toString()),
					transactionHash: event.transactionHash,
				};
			});

			const history = await Promise.all(historyPromises);
			const explorerBaseUrl = "https://testnet.teloscan.io/tx";

			const historyWithLinks = history.map((item) => ({
				...item,
				time: item.time.toLocaleString('en-GB', { 
					day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' 
				}),
				explorerLink: `${explorerBaseUrl}/${item.transactionHash}`,
			}));

			historyWithLinks.sort((a, b) => new Date(b.time) - new Date(a.time));

			setUserHistory(historyWithLinks);
		} catch (error) {
			console.error("Error fetching user history:", error);
		}
	};

	const cancelTransaction = () => {
		setCancelled(true);
		setIsSpinning(false);
		
	  };
    return (
// 		<div  className='flex justify-start flex-col overscroll-y-none overflow-clip '>
// 			 {shouldShowConfetti &&
// 				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				  <p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
// 				  <p className='text-white font-lufga font-bold text-[24px]'>You won {segments[resultString]} Telos</p>
// 				  <div onClick={reset}>
// 					<Button text='close'></Button>
// 				  </div>
// 				</div>
// 			  </div>
			  
// 				}
// 			 {isSpinning&&
// 				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				  <p className='text-white font-lufga font-bold text-[32px]'>Confirming Transaction...</p>
// 				  <img src='/pending.gif' />
				  
// 				</div>
// 			  </div>
			  
// 				}
// 			 {isLoading &&
// 				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				  <p className='text-white font-lufga font-bold text-[32px]'>Loading...</p>
// 				  <img src='/loading.gif' />
				  
// 				</div>
// 			  </div>
// 				}
			  
				
// 				{showErrorPopup && (
//     <div style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: 'rgba(0,0,0,0.5)',
//         zIndex: 100
//     }} onClick={() => setShowErrorPopup(false)}>
//         <div style={{
//             margin: '15% auto',
//             backgroundColor: '#fff',
//             padding: '20px',
//             borderRadius: '5px',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//             maxWidth: '500px',
//             textAlign: 'center',
//         }} onClick={(e) => e.stopPropagation()}>
//             <h2>Error</h2>
//             <p>{errorMessage}</p>
//             <button style={{
//                 marginTop: '15px',
//                 padding: '10px 20px',
//                 backgroundColor: '#007bff',
//                 color: 'white',
//                 border: 'none',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//             }} onClick={() => setShowErrorPopup(false)}>Close</button>
//         </div>
//     </div>
// )}
// 				{shouldShowNextTime&& 
// 				 <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
// 				 <div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
// 				   <p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
// 				   <img src={'/lost.gif'} />
// 				   <div onClick={reset}>
// 					 <Button text='close'></Button>
// 				   </div>
// 				 </div>
// 			   </div>
// 				}
				
// 			<div className='flex justify-between p-8 gap-20   items-center flex-row'>
// 				<p className='text-white font-lufga font-bold sm:text-[32px] text-[14px] '>CoinFlip</p>
// 				<div className={`hidden   flex-row  sm:flex content-center gap-2 bg-${location.pathname === '/' ? '[linear-gradient(_101deg,_#1f8ebe_0%,_#440495_39%,_#440495_69%,_#b102cd_98%_)] rounded-[16px] ' : 'black'} rounded-xl p-2  justify-center items center`}>
					
// 				<Link to='/' className={`text-white font-lufga font-bold text-[16px] `}>Home</Link>
// 					<Link to='/spinwheel' className={`text-white font-lufga font-bold text-[16px] `}>Spinwheel</Link>
// 				</div>
				
// 				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
// 					{currentAccount ? (
// 						<div className='flex sm:flex-row flex-col gap-4'>
					
							
			
							
// 							<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl sm:text-[16px] text-[12px] font-lufga bg-white' onClick={disconnectWalletHandler}><span>{formatWalletAddress(currentAccount)}</span></button>
// 							<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl text-black sm:text-[16px] text-[12px] font-lufga bg-white' onClick={Faucet} >
// 								Faucet
// 							</button>
// 						</div>
// 					) : (
						
						
// 						<button className='sm:w-40 sm:h-10 w-28 h-9 rounded-xl sm:text-[16px] text-[12px] font-lufga bg-white' onClick={connectWalletHandler}>Connect Wallet</button>
						
// 					)}
// 				</div>
// 			</div>
// 			<div className='flex flex-col w-screen gap-8 justify-start sm:pl-20 sm:pr-20 sm:pt-4 sm:pb-4  pl-8 pr-8 pt-2 pb-2 '>
			
					
					
						
// 			<div class=" font-lufga text-white flex justify-start font-regular text-[32px] ">Spinwheel</div>
						
						
						
						
					
				
// 				<div className='flex justify-center flex-col sm:h-[800px]  sm:rounded-[100px] rounded-[50px] items-center  bg-gradient-to-r from-pink-500 via-red-500 to-purple-600 p-1' >
// 					<div className='flex justify-start  sm:flex-row flex-col items-center w-full h-[700px] sm:h-full sm:rounded-[100px] rounded-[50px]  bg-black p-4'>
// 				<div  onClick={() => setTimeout(() => window.checkMemberGameType(),spinWheel(),window.buttonSpin.addEventListener("click", function(evt) {}),
// 					 10000)} className='flex absolute top-[16%] left-2 sm:left-0 sm:top-[30%]' >
						 
//  					 {/* <Wheel  items={segments} viewWheel={false} resultString={resultString} spinSpeed={50} /> */}
					
// 	 				</div>
// 					 <div className='w-[27%] h-[27vw] absolute top-[38%] left-[38%]'>
// 					<Wheel
// 					mustStartSpinning={mustSpin}
// 					prizeNumber={couponNum}
					
// 					onStopSpinning={() => {
// 						setSpinning(false);
// 						setMustSpin(false);
// 						handleOpen();
// 					}}
// 					/>
// 				</div>
					
					
					 
// 					 <div className='ml-44' onClick={() => spinWheel()}
// 					 disabled={isSpinning}>
// 					<Button text='Spin Wheel'></Button>
// 				</div>
// 					</div>
// 					<button onClick={() => setTimeout(() => window.checkMemberGameType(),spinWheel(),window.buttonSpin.addEventListener("click", function(evt) {}),
// 					 10000)} className='flex   sm:mt-2  justify-center flex-col sm:h-[80px] sm:w-[800px] h-[90px] w-full sm:rounded-[100px]  rounded-[100px] items-center  '>
							
// 							<h1 className='text-white font-lufga font-bold sm:text-[26px] items-center text-[20px]'>Click on the Wheel to Spin </h1>
							
						
// 					</button>
					
// 				</div>
				
				
// 				<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[100px] 	sm:mt-2 items-center border-white border sm:p-10 p-4'>
// 				<h3 className='text-white  font-lufga font-bold text-[24px]'>Your Results</h3>
// 					<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
// 						{userHistory.map((historyItem, index) => (
// 							<div className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px] '>
// 							<li key={index}>Time: {historyItem.time} </li>
// 							<li key={index}>Result: {historyItem.result} ETH </li>
							
							
// 							</div>
// 						))}
// 					</ul>
// 				</div>
// 			</div>
// 		</div>
<>
{!isMobile&&(
	<div className='flex flex-row h-screen'>
	{shouldShowConfetti &&
					<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
					<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[50%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[200px] h-[300px] sm:h-[380px] sm:w-[380px] bg-black border border-white'>
					<p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
					<p className='text-white font-lufga font-bold text-[24px]'>You won {segments[resultString]} Telos</p>
					<div onClick={() => setShouldShowConfetti(false)}>
						<Button text='close'></Button>
					</div>
					</div>
				</div>
				
					}
					{showErrorPopup && (
		<div style={{
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(0,0,0,0.5)',
			zIndex: 100
		}} onClick={() => setShowErrorPopup(false)}>
			<div style={{
				margin: '15% auto',
				backgroundColor: '#fff',
				padding: '20px',
				borderRadius: '5px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
				maxWidth: '500px',
				textAlign: 'center',
			}} onClick={(e) => e.stopPropagation()}>
				<h2>Error</h2>
				<p>{errorMessage}</p>
				<button style={{
					marginTop: '15px',
					padding: '10px 20px',
					backgroundColor: '#007bff',
					color: 'white',
					border: 'none',
					borderRadius: '5px',
					cursor: 'pointer',
				}} onClick={() => setShowErrorPopup(false)}>Close</button>
			</div>
		</div>
	)}
					{shouldShowNextTime&& 
					<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
					<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
					<p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
					<img src={'/lost.gif'} />
					<div onClick={() => setShouldShowNextTime(false)}>
						<Button text='close'></Button>
					</div>
					</div>
				</div>
					}
					{isSpinning&&
				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
				  <p className='text-white font-lufga font-bold text-[32px]'>Confirming Transaction...</p>
				  <img src='/pending.gif' />
				  <div onClick={() => cancelTransaction() }>
						<Button text='cancel'></Button>
					</div>
				  
				</div>
			  </div>
			  
				}
					
					{expandInstruction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
          <div className="absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[40%] sm:top-[55%] left-1/2 rounded-xl items-center flex flex-col justify-center gap-4 z-10 w-[250px] h-[400px] sm:h-[550px] sm:w-[550px] bg-[#05012D] border border-[#FEF2D0]">
            <span className="gold-text__highlight" data-text="Instructions">Instructions</span>
            <div >
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">1. First, connect your wallet.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">2. Each spin costs deafault gas fees.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">3. Click on the "Spin Wheel" button.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">4. Confirm the transaction using MetaMask.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">5. Wait for the wheel to stop spinning.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">6. The wheel will randomly select a segment.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">7. Your prize will be displayed after the spin.</p>
              <p className="text-white mt-4 text-[20px] font-gilroy  space-y-2">8. You can only spin once every 24 hours per device.</p>
            </div>
            
			<button  onClick={() => setExpandInstruction(false)}  className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 font-gilroy font-bold items-center rounded-[10px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Close
				</button>
          </div>
        </div>
      )}
					{rewardsOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
		<div className="absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center gap-4 z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-[#05012D] border border-[#FEF2D0]">
		  <span className="gold-text__highlight" data-text="Your Bets">Your Bets</span>
		  <ul className="text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto max-h-[300px] sm:max-h-[380px]">
		  {userHistory
        .slice()
        .sort((a, b) => new Date(b.time) - new Date(a.time))
        .map((historyItem, index) => (
          <li
            key={index}
            className="flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px]"
          >
            <div>Time: {new Date(historyItem.time).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            })} {new Date(historyItem.time).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            })}</div>
            <div>Result: {historyItem.result} TLOS</div>
            <div>
              <a
                href={historyItem.explorerLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                View on Explorer
              </a>
            </div>
          </li>
        ))}
		  </ul>
		  <button
			onClick={() => setRewardsOpen(false)}
			className="flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 font-gilroy font-bold items-center rounded-[10px]"
			style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}
		  >
			Close
		  </button>
		</div>
	  </div>
	  
      )}
				{ currentAccount &&(
				<div 
					className={`fixed top-28 right-0 z-1 flex items-center justify-center transition-all duration-300 ease-in-out`}
					style={{ 
						width: showSpinsRightSidebar ? '430px' : '30px',
						transform: showSpinsRightSidebar ? 'translateX(0)' : 'translateX(0)',
					}}
					>
					<button 
						className={`flex items-center justify-center w-30 h-[85px] text-[32px] border-[#FEF2D0] border rounded-l-[20px] shadow-lg transition-all duration-300 ease-in-out ${showSpinsRightSidebar ? 'bg-[#C18F5B]' : 'bg-[#211c55]'}`}
						onClick={() => setShowSpinsRightbar(!showSpinsRightSidebar)}
						style={{
						position: 'absolute',
						left: showSpinsRightSidebar ? '0' : 'auto',
						right: showSpinsRightSidebar ? 'auto' : '0',
						}}
					>
						{showSpinsLeftSidebar ? (
						<ChevronRight className='w-[54px] h-[54px] text-[#FEF2D0]'/>
						) : (
						<ChevronLeft className='w-[54px] h-[54px] text-[#FEF2D0]'/>
						)}
					</button>

					<div 
						className="bg-[#211c55] text-[#FEF2D0] border-[#FEF2D0] border font-gilroy font-medium rounded-l-[20px] shadow-lg transition-all duration-300 ease-in-out"
						style={{ 
						width: showSpinsRightSidebar ? '400px' : '0', 
						height: '85px', 
						overflow: 'hidden',
						marginLeft: showSpinsRightSidebar ? '30px' : '-30px', // Move it behind the button when closed
						opacity: showSpinsRightSidebar ? 1 : 0, // Fade out when closed
						visibility: showSpinsRightSidebar ? 'visible' : 'hidden', // Hide completely when closed
						}}
					>
						<div className="flex flex-col items-center justify-center h-full p-6">
						<span className="gold-text__highlight" data-text="Total Spins Left">Total Spins Left</span>
						<span className="gold-text__highlight" data-text={totalSpinsRemaining}>{totalSpinsRemaining}</span>
						</div>
					</div>
					</div>)
					}
			{/* <div className='w-1/5 pt-12 pl-8 pr-8 pb-12 justify-stretch relative'>
				<div>
				<h1 className='text-[36px] font-gilroy font-semibold text-white'>TelosCasino</h1>
				</div>
			
			<div className='gap-6 pl-[20%] pt-20 flex flex-col items-start justify-center'>
				<div className='flex flex-row gap-2 text-white items-center'><LayoutDashboard/>  <Link to='/' className='text-[22px] font-gilroy font-medium '>DASHBOARD</Link></div>
				<div className='flex flex-row gap-2 text-white items-center'><HandCoins/>  <Link to='/coinflip' className='text-[22px] font-gilroy font-medium text-white'>COINFLIP</Link></div>
				<div className='flex flex-row gap-2 text-[#FEF2D0]  items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[22px] font-gilroy font-medium text-white' style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>SPINWHEEL</Link></div>
			
				<div className="absolute left-[-50%] bottom-9 -mb-8 h-[500px] w-[450px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
				<div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
				</div>
				 
				



	

			</div>
			</div> */}
			 <div 
      className={`fixed top-0 left-0 z-10 flex items-start justify-start transition-all duration-300 ease-in-out`}
      style={{ 
        height: '100vh',
        width: showSpinsLeftSidebar ? '400px' : '80px',
        transform: showSpinsLeftSidebar ? 'translateX(0)' : 'translateX(0)',
      }}
    >
      <button 
        className={`flex items-start pt-[50px] justify-center w-20 h-[100%] text-[32px] border-[#FEF2D0] border-r  shadow-lg transition-all duration-300 ease-in-out bg-[#211c55]`}
        onClick={() => setShowSpinsLeftSidebar(!showSpinsLeftSidebar)}
        style={{
          position: 'absolute',
          left: showSpinsLeftSidebar ? '320px' : '0',
          right: showSpinsLeftSidebar ? 'auto' : '0',
        }}
      >
        {showSpinsLeftSidebar ? (
          <X className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        ) : (
          <Menu className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        )}
      </button>

      <div 
        className="bg-[#211c55] text-[#FEF2D0] border-[#FEF2D0] border-r font-gilroy font-medium shadow-lg transition-all duration-300 ease-in-out"
        style={{ 
          width: showSpinsLeftSidebar ? '100%' : '0', 
          height: '100%',
          overflow: 'hidden',
          opacity: showSpinsLeftSidebar ? 1 : 0,
          visibility: showSpinsLeftSidebar ? 'visible' : 'hidden',
          borderRight: '2px solid #FEF2D0',
        }}
      >
        <div className="flex flex-col items-center gap-10 justify-center h-full p-10">
        <div className='flex flex-row gap-2 text-white items-center'><LayoutDashboard/>  <Link to='/' className='text-[28px] font-gilroy font-medium '>DASHBOARD</Link></div>
				<div className='flex flex-row gap-2 text-white items-center'><HandCoins/>  <Link to='/coinflip' className='text-[28px] font-gilroy font-medium text-white'>COINFLIP</Link></div>
				<div className='flex flex-row gap-2 text-[#FEF2D0]  items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[28px] font-gilroy font-medium text-white'style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }} >SPINWHEEL</Link></div>
			
        </div>
      </div>
    </div>
					
			<div className='flex-grow flex-col pt-8 pl-32 pr-10 justify-between '>
				<div className='flex justify-between font-gilroy  font-medium relative p-2 items-center'>
				<div className="absolute top-0 right-0 -z-10 -mt-9 mr-9 h-[250px] w-[350px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
		<div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
	</div>
				<button onClick={()=>setRewardsOpen(true)} className='pt-4 pb-4 pl-6 pr-6 text-[20px] border  border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'><Gift className="text-[#FFDB93]"/>Rewards</button>
				<div className='flex flex-row gap-2'>
				<button className='p-2 pl-4 text-20px border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
				<img src='/telos.svg' width={22} height={22} />{walletBalance}
				<button className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Balance
				</button>
				</button>
				<button  onClick={Faucet}  className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 font-gilroy font-bold items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Faucet
				</button>
				</div>

				 <button >
				 <w3m-button balance='hode'  />
				</button>
				
				
				</div>
				<div className='border rounded-[50px] mt-4 flex flex-col pt-20 pb-20 pl-20 pr-20 justify-center items-center  border-[#FEF2D0] ' >
				<div className='flex flex-row h-[600px] rounded-[20px] w-full gap-8 justify-between bg-[#141135] '>
					<div className='flex flex-col justify-center w-[350px]  rounded-l-[20px] items-center gap-4 bg-[#211c55] pl-4 pr-4'>
						<p className='text-[#FEF2D0]  font-gilroy font-medium text-[34px]'>Spin The Wheel</p>
						<div onClick={() => setExpandInstruction(true)} className="text-white font-gilroy font-regular sm:text-[22px] text-[18px] cursor-pointer">
							<div className="flex items-center">
								<BadgeInfoIcon />
								<span className="ml-2">Read the instructions</span>
							</div>
							</div>
						
					
						
						<button onClick={() => spinWheel() } disabled={isSpinning} className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 w-full mb-4 font-gilroy font-medium text-[20px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
							Spin Wheel
						</button>
						{timeLeft && (
							<div className='text-center text-white font-gilroy text-[16px]'>
								Next spin available in: {timeLeft}
							</div>
						)}
						
						
						
						
						
					</div>
					<div className='flex flex-col p-10 w-[500px] items-center justify-center'>
						<div className='relative  w-full h-[80%] '>
							<Wheel
							mustStartSpinning={mustSpin}
							prizeNumber={couponNum}
							onStopSpinning={() => {
								setSpinning(false);
								setMustSpin(false);
								handleOpen();
							}}
							/>
						</div>
						</div>
					<div>

					</div>
				</div>
				
				</div>
				<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[50px] mt-8 items-center border-white border sm:p-10 p-4'>
					<h3 className='text-white font-lufga font-bold text-[24px]'>Your Bets</h3>
						<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
						{userHistory.map((historyItem, index) => (
				<div key={index} className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px]'>
					<li>Time: {historyItem.time}</li>
					<li>Result: {historyItem.result} TLOS</li>
					<div>
						<a
							href={historyItem.explorerLink}
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-500 underline"
						>
							View on Explorer
						</a>
					</div>
				</div>
			))}
					</ul>
					</div>

			</div>
		</div>
) }
{isMobile&&(
	<div className='flex flex-col p-2  h-screen'>
		{shouldShowConfetti &&
					<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
					<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center bg-[url(/public/win.gif)] z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
					<p className='text-white font-lufga font-bold text-[32px]'>WOHOOOO!!!</p>
					<p className='text-white font-lufga font-bold text-[24px]'>You won {segments[resultString]} Telos</p>
					<div onClick={() => setShouldShowConfetti(false)}>
						<Button text='close'></Button>
					</div>
					</div>
				</div>
				
					}
					{showErrorPopup && (
		<div style={{
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(0,0,0,0.5)',
			zIndex: 100
		}} onClick={() => setShowErrorPopup(false)}>
			<div style={{
				margin: '15% auto',
				backgroundColor: '#fff',
				padding: '20px',
				borderRadius: '5px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
				maxWidth: '500px',
				textAlign: 'center',
			}} onClick={(e) => e.stopPropagation()}>
				<h2>Error</h2>
				<p>{errorMessage}</p>
				<button style={{
					marginTop: '15px',
					padding: '10px 20px',
					backgroundColor: '#007bff',
					color: 'white',
					border: 'none',
					borderRadius: '5px',
					cursor: 'pointer',
				}} onClick={() => setShowErrorPopup(false)}>Close</button>
			</div>
		</div>
	)}
					{shouldShowNextTime&& 
					<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
					<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
					<p className='text-white font-lufga font-bold text-[32px]'>Better Luck Next Time</p>
					<img src={'/lost.gif'} />
					<div onClick={() => setShouldShowNextTime(false)}>
						<Button text='close'></Button>
					</div>
					</div>
				</div>
					}
								{expandInstruction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
          <div className="absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center gap-4 z-10 w-[300px] h-[500px] sm:h-[500px] sm:w-[500px] bg-[#05012D] border border-[#FEF2D0]">
            <span className="gold-text__highlight" data-text="Instructions">Instructions</span>
            <div >
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">1. First, connect your wallet.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">2. Each spin costs deafault gas fees.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">3. Click on the "Spin Wheel" button.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">4. Confirm the transaction using MetaMask.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">5. Wait for the wheel to stop spinning.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">6. The wheel will randomly select a segment.</p>
              <p className="text-white mt-4 text-[14px] font-gilroy  space-y-2">7. Your prize will be displayed after the spin.</p>
              <p className="text-white mt-4 text-[14[px] font-gilroy  space-y-2">8. You can only spin once every 24 hours per device</p>
            </div>
            
			<button  onClick={() => setExpandInstruction(false)}  className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 font-gilroy font-bold items-center rounded-[10px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Close
				</button>
          </div>
        </div>
      )}
					{isSpinning&&
				<div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
				<div className='absolute p-4 text-center transform -translate-x-1/2 -translate-y-1/2 top-[50%] sm:top-[60%] left-1/2 rounded-xl items-center flex flex-col justify-center z-10 w-[250px] h-[400px] sm:h-[500px] sm:w-[500px] bg-black border border-white'>
				  <p className='text-white font-lufga font-bold text-[32px]'>Confirming Transaction...</p>
				  
				  <img  src='/pending.gif' />
				  <div onClick={() => setIsSpinning(false)}>
						<Button text='Cancel'></Button>
					</div>
				</div>
			  </div>
			  
				}
				 <div 
      className={`absolute top-[103px] right-2 z-[10] flex items-center justify-center transition-all duration-300 ease-in-out`}
      style={{ 
        width: showSpinsLeftSidebar ? '230px' : '30px',
      }}
    >
      <div 
        className="bg-[#211c55] text-[#FEF2D0] border-[#FEF2D0] border font-gilroy font-medium rounded-l-[20px] shadow-lg transition-all duration-300 ease-in-out"
        style={{ 
          width: showSpinsLeftSidebar ? '400px' : '0', 
          height: '50px', 
          overflow: 'hidden',
          marginLeft: showSpinsLeftSidebar ? '30px' : '-30px', // Move it behind the button when closed
          opacity: showSpinsLeftSidebar ? 1 : 0, // Fade out when closed
          visibility: showSpinsLeftSidebar ? 'visible' : 'hidden', // Hide completely when closed
          transitionDelay: showSpinsLeftSidebar ? '0s' : '0.3s', // Delay hiding for smooth transition
        }}
      >
        <div className="flex flex-col items-center justify-center h-full p-6">
          <span className="gold-text__highlight" data-text="Total Spins Left">Total Spins Left</span>
          <span className="gold-text__highlight" data-text={totalSpinsRemaining}>{totalSpinsRemaining}</span>
        </div>
      </div>

      <button 
        className={`flex items-center justify-center w-[45px] h-[50px] text-[32px] border-[#FEF2D0] border rounded-l-[10px] shadow-lg transition-all duration-300 ease-in-out ${showSpinsLeftSidebar ? 'bg-[#C18F5B]' : 'bg-[#211c55]'}`}
        onClick={() => setShowSpinsLeftSidebar(!showSpinsLeftSidebar)}
        style={{
          position: 'absolute',
          left: showSpinsLeftSidebar ? '0' : 'auto',
          right: showSpinsLeftSidebar ? 'auto' : '0',
          transitionDelay: showSpinsLeftSidebar ? '0.3s' : '0s', // Delay button transition
        }}
      >
        {showSpinsLeftSidebar ? (
          <ChevronRight className='w-[54px] h-[54px] text-[#FEF2D0]'/>
        ) : (
          <ChevronLeft className='w-[54px] h-[54px] text-[#FEF2D0]'/>
        )}
      </button>
    </div>
	<div className='flex flex-row pt-8 pl-2 pr-2 pb-4 gap-2 justify-evenly  relative'>
		
		<h1 className='text-[18px] font-gilroy font-semibold text-white'>TelosCasino</h1>
		<button className='pt-1 pb-1 pr-3 pl-3 text-[13px] font-gilroy border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
		  <img src='/telos.svg' width={16} height={16} />{walletBalance}
		  
		
		</button>
		<button  onClick={Faucet}  className=' flex flex-row justify-center pl-2 pr-2 pt-1 pb-1 font-gilroy text-[12px] font-medium items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
					Faucet
				</button>


		<button  onClick={connectWallet} className=' flex flex-row justify-center font-gilroy font-medium text-[12px] pl-2 pr-2 pt-1 pb-1 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
		   {currentAccount?formatWalletAddress(currentAccount).slice(0, 9): 'Connect'}
		  </button>
	   
	   
	</div>
	<div className='border rounded-[20px] h-full  flex flex-col p-4 items-center border-[#FEF2D0] ' >
	<div className='flex flex-col p-14 '>
					<div className='w-[80%] h-[80vw] absolute top-[22%] left-[10%]'>
 					<Wheel
					mustStartSpinning={mustSpin}
					prizeNumber={couponNum}
					
					onStopSpinning={() => {
						setSpinning(false);
						setMustSpin(false);
						handleOpen();
					}}
					/>
				</div>
					</div>
					<div className='flex flex-col  justify-center w-[350px] mt-[280px] rounded-b-[20px] items-center gap-2 bg-[#211c55]  pt-2 pl-4 pr-4'>
						<p className='text-[#FEF2D0]  font-gilroy font-medium text-[28px]'>Spin The Wheel</p>
						<div onClick={() => setExpandInstruction(true)} className="text-white font-gilroy font-regular sm:text-[22px] text-[18px] cursor-pointer">
							<div className="flex items-center">
								<BadgeInfoIcon />
								<span className="ml-2">Read the instructions</span>
							</div>
							</div>
						
						

					
						
						<button onClick={() => spinWheel() } disabled={isSpinning} className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 w-full mb-4 font-gilroy font-medium text-[20px] items-center rounded-[20px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
							Spin Wheel
						</button>
						{timeLeft && (
							<div className='text-center text-white font-gilroy text-[16px]'>
								Next spin available in: {timeLeft}
							</div>
						)}
						
						
						
						
					</div>
			
		  
		  
		</div>
		{userHistory.length > 0&&<div className='flex justify-start gap-2 flex-col h-[700px] rounded-[20px] mt-5  items-center border-[#FEF2D0] border sm:p-10 w-full p-2'>
					<h3 className='text-white font-lufga font-bold text-[24px]'>Your Bets</h3>
						<ul className='text-white sm:p-4 p-2 rounded-xl w-full overflow-y-auto sm:max-h-[500px] max-h-[580px]'>
						{userHistory.map((historyItem, index) => (
							<div key={index} className='flex p-4 rounded-xl mt-4 mb-4 bg-[#d9d9d960] flex-row justify-evenly text-white font-lufga font-bold sm:text-[18px] text-[10px]'>
								<li>Time: {historyItem.time}</li>
								<li>Result: {historyItem.result} TLOS</li>
								<div>
									<a
										href={historyItem.explorerLink}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-500 underline"
									>
										View on Explorer
									</a>
								</div>
							</div>
						))}
					</ul>
					</div>}
  </div>
)}
</>

    );
}

export default SpinWheel;
