import React, { useState } from 'react';
import { ethers } from 'ethers';

const contractAddress = '0xBa5fcca20B070221661076A62cC225071DDd78ee';
const contractABI = [
	{
		"inputs": [],
		"name": "addLiquidity",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "LiquidityAdded",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "LiquidityRemoved",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "removeLiquidity",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "spinWheel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "reward",
				"type": "uint256"
			}
		],
		"name": "Spun",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "lastResetTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "spinsRemaining",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalLiquidity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

function OwnerPanel2() {
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAddLiquidity = async (e) => {
        e.preventDefault();
        if (!amount) {
            alert("Please enter an amount.");
            return;
        }
        setLoading(true);

        try {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(contractAddress, contractABI, signer);

                const tx = await contract.addLiquidity({ value: ethers.utils.parseEther(amount) });
                await tx.wait();
                alert("Liquidity added successfully!");
            } else {
                alert("Please install MetaMask.");
            }
        } catch (err) {
            console.error(err);
            alert("Failed to add liquidity: " + err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveLiquidity = async (e) => {
        e.preventDefault();
        if (!amount) {
            alert("Please enter an amount.");
            return;
        }
        setLoading(true);

        try {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(contractAddress, contractABI, signer);

                const tx = await contract.removeLiquidity(ethers.utils.parseEther(amount));
                await tx.wait();
                alert("Liquidity removed successfully!");
            } else {
                alert("Please install MetaMask.");
            }
        } catch (err) {
            console.error(err);
            alert("Failed to remove liquidity: " + err.message);
        } finally {
            setLoading(false);
        }
    };

    
    return (
        <div className="flex flex-col items-center mt-10">
            <h2 className="text-2xl font-semibold text-[#FEF2D0] mb-4">Owner Panel</h2>
            <form className="w-full justify-center flex flex-col items-center max-w-xs">
                <input 
                    type="text" 
                    placeholder="Amount in ETH" 
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4 text-center"
                />
                <div className="flex space-x-2">
                    <button 
                        onClick={handleAddLiquidity} 
                        disabled={loading}
                        className={`bg-blue-500 text-white px-4 py-2 rounded shadow ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Processing...' : 'Add Liquidity'}
                    </button>
                    <button 
                        onClick={handleRemoveLiquidity} 
                        disabled={loading}
                        className={`bg-red-500 text-white px-4 py-2 rounded shadow ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Processing...' : 'Remove Liquidity'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default OwnerPanel2;
