import React , { useState, useEffect }from 'react'
import { Link } from 'react-router-dom'
import { LayoutDashboard,HandCoins,FerrisWheel,Gift,ChevronRight,ChevronLeft, Menu, X } from 'lucide-react';
import { ethers } from 'ethers';
import { useMediaQuery } from 'react-responsive';
import { useWeb3Modal, useWeb3ModalAccount,useWalletInfo,useSwitchNetwork,useWeb3ModalProvider  } from '@web3modal/ethers5/react';
import { useNetworkMismatch,useSwitchChain } from "@thirdweb-dev/react";
import {TelosEvmTestnet} from '@thirdweb-dev/chains'
const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [walletBalance, setWalletBalance] = useState('');
  const [currentAccount, setCurrentAccount] = useState(null);
  const web3Modal = useWeb3Modal();
  const wallet = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider()
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isBanned,setBanned]=useState(false);
  const contractAddress ='0x5C407066c5a18d76571d1071575bB029c2921E7d'
  const [loading,setLoading] = useState(false);
  const [collapsed,setCollapsed] = useState(true);
  const [showSpinsLeftSidebar, setShowSpinsLeftSidebar] = useState(false);
  const isMismatched = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const contractABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressBanned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "AddressUnbanned",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "AdminAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "AdminRemoved",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "addAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "banAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "unbanAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "addAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "removeAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "isBanned",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_address",
                "type": "address"
            }
        ],
        "name": "isAdmin",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllAdminAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "addresses",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "bannedAddresses",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "admins",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]
  
  










  
  
 

 
const Telos = {
	chainId: 41, // Hexadecimal version of 80001, prefixed with 0x
	chainName: 'Telos EVM Testnet',
	nativeCurrency: {
	  name: 'Telos',
	  symbol: 'TLOS',
	  decimals: 18,
	},
	rpcUrls: ['https://testnet.telos.net/evm'],
	blockExplorerUrls: ['https://testnet.teloscan.io/'],
  };
  
  
  useEffect(() => {
   
    if (wallet) {
      setCurrentAccount(wallet.address);
      console.log(wallet)
      fetchWalletBalance(wallet.address);
 
     
    }
  }, [wallet]);
  useEffect(() => {
    if (currentAccount) {
      checkAddressStatus(currentAccount);
    }
  }, [currentAccount]);
  const formatWalletAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};
	async function checkIfWalletIsConnected() {
		try {
			if (window.ethereum) {
				const accounts = await window.ethereum.request({ method: 'eth_accounts' });
				if (accounts.length > 0) {
					setCurrentAccount(accounts[0]);
				
					await fetchWalletBalance(accounts[0]); 
					
				}
			} else {
				console.log("Ethereum object doesn't exist!");
			}
		} catch (error) {
			console.error("Failed to check if wallet is connected:", error);
		}
	}
  async function checkAddressStatus(address) {

      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        
    
        
        const isBanned = await contract.isBanned(address);
        if (isBanned) {
          setBanned(true)
          console.log("You are banned from accessing this application.");
        } else {
          // Address is not banned, proceed
          const allAddresses = await contract.getAllAddresses();
  
          const isWhitelisted = allAddresses.includes(address);
          setIsWhitelisted(isWhitelisted);
  
          if (!isWhitelisted) {
            // If the address is not whitelisted, add it
            
            await contract.addAddress(address);
            console.log("Address added to whitelist.");
          } else {
            console.log("Address is already whitelisted.");
          }
        }
          
        

        
      }
       catch (error) {
        console.error("Failed to check if wallet is whitelisted:", error);
      }
      // Check if the address is banned
     
      
      
     
  }

  async function checkWhitelisted(address) {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
      // Check if the address is whitelisted
      const whitelisted = await contract.isWhitelisted(address);
      setIsWhitelisted(whitelisted);
      
      // If the address is not whitelisted, add it to the pending list
      // if (!whitelisted) {
      //   await contract.addToPending(address);
      //   console.log("Address added to pending list:", address);
      // }
    } catch (error) {
      console.error("Failed to check if wallet is whitelisted:", error);
    }
  }
  async function addtoPending() {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      
  
      
        await contract.addToPending(currentAccount);
      
      }
     catch (error) {
      console.error("Failed to check if wallet is whitelisted:", error);
    }
  }
  
  
  const BlurOverlay = ({ message }) => (
    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg text-center">
        <p className="text-black mb-4">{message}</p>
        {!currentAccount && (
        
          <button  className="px-4 py-2 rounded-lg ">
              <w3m-button/>
         
          </button>
        )}
        
      </div>
    </div>
  );
  
  async function fetchWalletBalance(account) {
		try {
      
      if (web3Modal) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
  
        // Ensure the provider is connected
        await provider.send("eth_requestAccounts", []);
        
        const balance = await provider.getBalance(account);
        const formattedBalance = ethers.utils.formatEther(balance);
        const walletBalance = String(formattedBalance).slice(0, 4) + '...';
        setWalletBalance(walletBalance);
       
      } else {
        console.error("Web3 provider is not available.");
      }
    } catch (error) {
      console.error("Failed to fetch wallet balance:", error);
    }
    setLoading(false)
	}
  async function connectWallet() {
    setLoading(true);
    try {
      await web3Modal.open();
      
     
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  }
//   async function switchToTelos() {
//     try {
//       await switchNetwork(Telos.chainId);
      
//     } catch (error) {
//       console.error("Failed to switch network:", error);
//     }
    
    
// }

  return (
    <>
     
    {!isMobile&&(<div className='flex flex-row '>
        
    {!currentAccount && (
            <BlurOverlay message="Please connect your wallet to continue." />
          )}
          {currentAccount && isBanned && (
            <BlurOverlay message="Your Banned for suspicious activity" />
          )}
         
        {/* <div className='w-1/5 pt-12 pl-8 pr-8 pb-12 justify-stretch relative'>
            <div>
            <h1 className='text-[36px] font-gilroy font-semibold text-white'>TelosCasino</h1>
            </div>
           
           <div className='gap-6 pl-[20%] pt-20 flex flex-col items-start justify-center'>
            <div className='flex flex-row gap-2 text-[#FEF2D0] items-center'><LayoutDashboard/>  <Link to='/' className='text-[22px] font-gilroy font-medium 'style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>DASHBOARD</Link></div>
            <div className='flex flex-row gap-2 text-white items-center'><HandCoins/>  <Link to='/coinflip' className='text-[22px] font-gilroy font-medium text-white'>COINFLIP</Link></div>
            <div className='flex flex-row gap-2 text-white items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[22px] font-gilroy font-medium text-white'>SPINWHEEL</Link></div>
           
            <div className="absolute left-[-50%] bottom-9 -mb-8 h-[500px] w-[450px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
              <div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
            </div>


 

           </div>
        </div> */}
         <div 
      className={`fixed top-0 left-0 z-10 flex items-start justify-start transition-all duration-300 ease-in-out`}
      style={{ 
        height: '100vh',
        width: showSpinsLeftSidebar ? '400px' : '80px',
        transform: showSpinsLeftSidebar ? 'translateX(0)' : 'translateX(0)',
      }}
    >
      <button 
        className={`flex items-start pt-[50px] justify-center w-20 h-[100%] text-[32px] border-[#FEF2D0] border-r  shadow-lg transition-all duration-300 ease-in-out bg-[#211c55]`}
        onClick={() => setShowSpinsLeftSidebar(!showSpinsLeftSidebar)}
        style={{
          position: 'absolute',
          left: showSpinsLeftSidebar ? '320px' : '0',
          right: showSpinsLeftSidebar ? 'auto' : '0',
        }}
      >
        {showSpinsLeftSidebar ? (
          <X className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        ) : (
          <Menu className='w-[32px] h-[32px] text-[#FEF2D0]'/>
        )}
      </button>

      <div 
        className="bg-[#211c55] text-[#FEF2D0] border-[#FEF2D0] border-r font-gilroy font-medium shadow-lg transition-all duration-300 ease-in-out"
        style={{ 
          width: showSpinsLeftSidebar ? '100%' : '0', 
          height: '100%',
          overflow: 'hidden',
          opacity: showSpinsLeftSidebar ? 1 : 0,
          visibility: showSpinsLeftSidebar ? 'visible' : 'hidden',
          borderRight: '2px solid #FEF2D0',
        }}
      >
        <div className="flex flex-col items-center gap-10 justify-center h-full p-10">
        <div className='flex flex-row gap-2 text-white items-center'><LayoutDashboard/>  <Link to='/' className='text-[28px] font-gilroy font-medium 'style={{ '--text-gradient-from': '#FEF2D0', '--text-gradient-to': '#C18F5B', backgroundImage: 'linear-gradient(to right, var(--text-gradient-from), var(--text-gradient-to))', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>DASHBOARD</Link></div>
				<div className='flex flex-row gap-2 text-white items-center'><HandCoins/>  <Link to='/coinflip' className='text-[28px] font-gilroy font-medium text-white'>COINFLIP</Link></div>
				<div className='flex flex-row gap-2 text-[#FEF2D0]  items-center'><FerrisWheel/>  <Link to='/spinwheel' className='text-[28px] font-gilroy font-medium text-white' >SPINWHEEL</Link></div>
			
        </div>
      </div>
    </div>
					
        <div className='flex-grow flex-col pt-8 pl-32 pr-[65px] w-[60%] xl:w-full justify-center items-center '>
            <div className='flex justify-between font-gilroy font-medium relative p-2 items-center '>
            <div className="absolute top-0 right-0 -z-10 -mt-9 mr-9 h-[250px] w-[350px] rounded-full overflow-hidden" style={{ filter: 'blur(120px)' }}>
    <div className="h-full w-full rounded-full" style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 1, 45, 1), #314dad, rgba(5, 1, 45, 1))', opacity: '0.7' }}></div>
  </div>
              <button className='pt-4 pb-4 pl-6 pr-6 text-[20px] border  border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'><Gift className="text-[#FFDB93]"/>Rewards</button>
              <button className='p-2 pl-4 text-20px border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
              <img src='/telos.svg' width={22} height={22} />{walletBalance}
              <button className=' flex flex-row justify-center pl-6 pr-6 pt-2 pb-2 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
                Balance
              </button>
            </button>


            <button  >
               <w3m-button  balance='hide' />
              </button>
              
            </div>
            <div className='border rounded-[50px] mt-4 flex flex-col p-2    border-[#FEF2D0] ' >
              <div  style={{ height: '400px'  }}>
                <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} className='rounded-[50px]' src='/banner3.png' />
              </div>
              <div className='flex flex-col'>
                <div className=' flex flex-row  justify-between pt-8 pl-8 pr-8'>
                  <h1 className='text-[24px] font-gilroy font-medium text-white'>All Games</h1>
                  <button className=' flex flex-row justify-center pl-4 pr-4 pt-1 pb-1 items-center font-gilroy font-normal rounded-[10px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
                    Show All
                  </button>
                </div>
                <div>
                  <div className='flex flex-row justify-start gap-8 p-8'>
                    
                  <Link to='/coinflip' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/coinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Coinflip</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 2x your bet</p>
                    </div>
                  </Link>
                  
                  <Link to='/spinwheel' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/spinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Spin Wheel</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 5x your bet</p>
                    </div>
                  </Link>
                  <Link to='/coinflip' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/coinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Coinflip</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 2x your bet</p>
                    </div>
                  </Link>
                  
                  <Link to='/spinwheel' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/spinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Spin Wheel</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 5x your bet</p>
                    </div>
                  </Link>
                  <Link to='/coinflip' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/coinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Coinflip</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 2x your bet</p>
                    </div>
                  </Link>
                  
                  <Link to='/spinwheel' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/spinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Spin Wheel</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 5x your bet</p>
                    </div>
                  </Link>
                  <Link to='/coinflip' className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/coinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Coinflip</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 2x your bet</p>
                    </div>
                  </Link>
               
                   
                  


                     
                    
                  </div>
                </div>
              </div>
              
            </div>

        </div>
    </div>)}
    {isMobile&&(
      
      <div className='flex flex-col  h-screen'>
        {!currentAccount && (
            <BlurOverlay message="Please connect your wallet to continue." />
          )}
          
        <div className='flex flex-row pt-8 pl-2 pr-2 pb-4 justify-evenly  relative'>
            
            <h1 className='text-[16px] font-gilroy font-semibold text-white'>TelosCasino</h1>
            <button className='pt-1 pb-1 pr-3 pl-3 text-[10px] font-gilroy border border-gray-600 text-white rounded-[50px] gap-2 flex flex-row justify-center items-center'>
              <img src='/telos.svg' width={16} height={16} />{walletBalance}
              <button className=' flex flex-row justify-center pl-1 pr-1 pt-1 pb-1 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
                Balance
              </button>
            </button>
       


            <button onClick={connectWallet()}   className=' flex flex-row justify-center font-gilroy text-[12px] pl-2 pr-2 pt-1 pb-1 z-10 items-center rounded-[50px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
               {currentAccount?formatWalletAddress(currentAccount): 'Connect'}
              </button>
           
           
        </div>
        <div className='border rounded-[20px] h-screen  flex flex-col p-2   border-[#FEF2D0] ' >
              <div style={{ height: '200px', width: '100%' }}>
                <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} className='rounded-[20px]' src='/banner3.png' />
              </div>
              <div className='flex flex-col'>
                <div className=' flex flex-row  justify-between pt-4 pl-4 pr-4'>
                  <h1 className='text-[14px] font-gilroy font-medium text-white'>All Games</h1>
                  <button className=' flex flex-row justify-center pl-2 pr-2 pt-1 pb-1 items-center font-gilroy text-[14px] rounded-[10px]' style={{ backgroundImage: 'linear-gradient(to right, #FEF2D0, #C18F5B)', color: 'black' }}>
                    Show All
                  </button>
                </div>
                <div>
                  <div className='flex flex-row justify-start gap-8 pt-10 pl-6 pr-6 pb-6'>
                  <Link to='/coinflip' >
                  <div className='flex flex-col items-center bg-[#180E51] p-2  rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/coinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Coinflip</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 2x your bet</p>
                    </div>
                  </div>
                  </Link>
                  <Link to='/spinwheel'>
                  <div className='flex flex-col items-center bg-[#180E51] p-2 rounded-[10px] h-[200px] w-[150px] gap-2'>
                    <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='rounded-[10px]' src='/spinbg.png' />
                    <div className='flex flex-col'>
                      <h1 className='text-[20px] font-gilroy font-medium text-white'>Spin Wheel</h1>
                      <p className='text-[14px] font-gilroy font-normal text-[#3DD409]'>Win up to 5x your bet</p>
                    </div>
                  </div>
                  </Link>
                  

                     
                    
                  </div>
                </div>
              </div>
              
            </div>
      </div>
    )}
    
    </>


  )
}

export default Home