import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';

// Replace with your contract's ABI
const contractABI = [
	{
		"inputs": [],
		"name": "adjustHouseBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "choice",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"name": "BetPlaced",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "win",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "payout",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"name": "BetResult",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "depositFunds",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FundsDeposited",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "FundsWithdrawn",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_choice",
				"type": "bool"
			}
		],
		"name": "placeBet",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "getHouseBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getUserBets",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "choice",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "win",
						"type": "bool"
					},
					{
						"internalType": "uint256",
						"name": "payout",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"internalType": "struct CoinFlip.Bet[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "houseBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumBet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userBets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "choice",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "win",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "payout",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "timestamp",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const contractAddress = "0x26A90CCc53879f3466Dd1F6f970B25D9D62Ff7be";

function OwnerPanel() {
    const [houseBalance, setHouseBalance] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');

    async function requestAccount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
    }

    async function fetchHouseBalance() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(contractAddress, contractABI, provider);
            try {
                const balance = await contract.getHouseBalance();
                setHouseBalance(ethers.utils.formatEther(balance));
            } catch (error) {
                console.error('Error fetching house balance:', error);
            }
        }
    }

    async function depositFunds() {
      if (!amount) return;
      await requestAccount();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      try {
          const transactionResponse = await contract.depositFunds({ value: ethers.utils.parseEther(amount) });
          await transactionResponse.wait();
          setMessage('Funds deposited successfully!');
          fetchHouseBalance();
      } catch (error) {
          console.error('Error depositing funds:', error);
          setMessage('Error depositing funds. See console for details.');
      }
  }

  async function withdrawFunds() {
      if (!amount) return;
      await requestAccount();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      try {
          const transactionResponse = await contract.withdrawFunds(ethers.utils.parseEther(amount));
          await transactionResponse.wait();
          setMessage('Funds withdrawn successfully!');
          fetchHouseBalance();
      } catch (error) {
          console.error('Error withdrawing funds:', error);
          setMessage('Error withdrawing funds. See console for details.');
      }
  }

  async function adjustHouseBalance() {
      await requestAccount();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      try {
          const transactionResponse = await contract.adjustHouseBalance();
          await transactionResponse.wait();
          setMessage('House balance adjusted successfully!');
          fetchHouseBalance();
      } catch (error) {
          console.error('Error adjusting house balance:', error);
          setMessage('Error adjusting house balance. See console for details.');
      }
  }

  useEffect(() => {
      fetchHouseBalance();
  }, []);

  return (
    <div className="flex flex-col items-center mt-10">
      <h3 className="text-2xl font-semibold text-[#FEF2D0] mb-4">House Balance: {houseBalance} Telos</h3>
      <div className="w-full max-w-xs">
        <input
          type="text"
          placeholder="Amount in Telos"
          className="w-full p-2 border border-gray-300 rounded mb-4 text-center"
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="flex space-x-2">
          <button
            onClick={depositFunds}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow"
          >
            Deposit Funds
          </button>
          <button
            onClick={withdrawFunds}
            className="bg-red-500 text-white px-4 py-2 rounded shadow"
          >
            Withdraw Funds
          </button>
          <button
            onClick={adjustHouseBalance}
            className="bg-green-500 text-white px-4 py-2 rounded shadow"
          >
            Adjust House Balance
          </button>
        </div>
        <p className="mt-4 text-center text-red-600">{message}</p>
      </div>
    </div>
  );
}

export default OwnerPanel;